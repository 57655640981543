import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import contactUsResources from './contactUsResources';
import './_contact-us.scss';
import selectors from './contactUsSelectors';
import Spinner from '../../../../components/Spinner/Spinner';
import ContactUsForm from './ContactUsForm';

function ContactUs({ isLoading }) {
    return <div>
        {
            isLoading ?
                <div>
                    <Spinner />
                </div >
                : <></>
        }
        <div className="contact-us">
            <div className="contact-us__connect">
                <div className="contact-us__connect-form">
                    <div className="contact-us__connect-form-title">{contactUsResources.title}</div>
                    <div className="contact-us__form-group">
                        <ContactUsForm />
                    </div>
                </div>
            </div>
            <div className="contact-us__contacts-wrapper">
                <div className="contact-us__contacts-title">
                    Here To <span className="green">Help</span>
                </div>
                <div className="contact-us__contacts">
                    <div className="contact-us__contacts-subtitle">
                        Contact
                    </div>
                    <div className="contact-us__contacts-description-wrapper">
                        <div className="contact-us__contacts-description">
                            Sales:
                            <a className="contact-us__link" href="mailto:wellness@healthycontributions.com">wellness@healthycontributions.com</a>
                        </div>
                        <div className="contact-us__contacts-description">
                            Support:
                            <a className="contact-us__link" href="mailto:info@healthycontributions.com.">info@healthycontributions.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;

};

ContactUs.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

export default connect(selectors, null)(ContactUs);
