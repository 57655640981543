import http from '../../../http/base';

export default ({ API_PATHS }) => ({
    getReimbursementHistory: memberGuids => http.get(API_PATHS.reimbursementHistory, { params: { memberGuids } }),
    getPlanReimbursementsGroup: (planReimbursementsGroup) => {
        const { memberGuids, planId, submissionPeriodFrom, submissionPeriodTo } = planReimbursementsGroup.toJS();
        return http.get(API_PATHS.planReimbursementsGroup,
            {
                params: {
                    planId,
                    memberGuids,
                    submissionPeriodFromId: submissionPeriodFrom.id,
                    submissionPeriodToId: submissionPeriodTo.id
                }
            });
    }
});