import React from 'react';
import routes from '../../routing/routes';
import allyant from './allyant.png';
import { API_PATHS } from '../../http/configs';
import { NavLink } from 'react-router-dom';
import footerResources from './footerResources';
import './footer.scss';
import Image from '../Image/Image';
import FooterMenuItem from './FooterMenuItem';
import { HEALTHY_CONTRIBUTIONS_LEGAL_NAME } from '../../constants/companyNames';

function Footer() {
    return (
        <div className="footer">
            <div className="footer__content">
                <div className="footer__menu-container">
                    <ul className="footer__menu-list">
                        <FooterMenuItem description={footerResources.sectionNameSolutions} active>
                            <ul className="footer-menu-item__submenu-list">
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.forFacilities.path}
                                    >
                                        {footerResources.linkNameForFacilities}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.forEmployers.path}
                                    >
                                        {footerResources.linkNameForEmployers}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.forMembers.path}
                                    >
                                        {footerResources.linkNameForMembers}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.forInsurers.path}
                                    >
                                        {footerResources.linkNameForInsurers}
                                    </NavLink>
                                </li>
                            </ul>
                        </FooterMenuItem>
                        <FooterMenuItem description={footerResources.sectionNameContact}>
                            <ul className="footer-menu-item__submenu-list">
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.aboutUs.path}
                                    >
                                        {footerResources.linkNameAbout}
                                    </NavLink>
                                </li>
                            </ul>
                        </FooterMenuItem>
                        <FooterMenuItem description={footerResources.sectionNameLegal}>
                            <ul className="footer-menu-item__submenu-list">
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.privacynotice.path}
                                    >
                                        {footerResources.linkNamePrivacyNotice}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.consumerhealthprivacynotice.path}
                                    >
                                        {footerResources.linkNameConsumerHealthPrivacyNotice}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.termsofuse.path}
                                    >
                                        {footerResources.linkNameTermsOfUse}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="footer-menu-item__submenu-link"
                                        to={routes.accessibilityAndMaintenance.path}
                                    >
                                        {footerResources.linkNameAccessibilityAndMaintenance}
                                    </NavLink>
                                </li>
                            </ul>
                        </FooterMenuItem>
                    </ul>
                </div >
                <div className="footer__container">
                    <div className="footer__privacy-container">
                        <div className="footer__logo-container">
                            <a href={routes.home.path} className="footer__logo-image-wrapper">
                                <Image image="logoWhite" alt={'Healthy Contributions Logo'} />
                            </a>
                            <div className="footer__copyright-container footer__copyright-text">
                                Copyright © {new Date().getFullYear()} {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}. All rights reserved.
                            </div>
                        </div>
                        <div className="footer__cookie-container" dangerouslySetInnerHTML={{ __html: '<a id="ot-sdk-link" className="ot-sdk-show-settings" href="#" alt="Cookie Settings" onclick="window.OneTrust.ToggleInfoDisplay()">Cookie Settings</a>' }} />
                    </div>
                    <div className="footer__allyant-container ">
                        <div className="footer__text">
                            {footerResources.headerAllyant}
                        </div>
                        <a href={API_PATHS.allyant} className="footer__img">
                            <img src={allyant} alt={'Icon Allyant'} />
                        </a>

                    </div>
                </div>
            </div >
        </div >
    );
}

export default Footer;
