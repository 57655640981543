import React from 'react';
import PropTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './_custom-popup-body.scss';
import MaterialIcon from '../../MaterialIcon/MaterialIcon';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';

export function CustomPopupBody({ onCancel, body, title, isLoading, subtitle }) {
    return isLoading ?
        <div>
            <Spinner />
        </div>
        : <div className="custom-popup">
            <button className="custom-popup__close-icon-button" onClick={onCancel}>
                <MaterialIcon icon="clear" />
            </button>
            <div className="custom-popup__title-wrapper">
                {title && <div className="custom-popup__title">
                    {title}
                </div>}
                {subtitle && <div className="custom-popup__subtitle">
                    {subtitle}
                </div>}
            </div>
            {body}
        </div>;
};

CustomPopupBody.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onLogin: PropTypes.func,
    dispatch: PropTypes.func,
    loginHandler: PropTypes.func,
    body: PropTypes.object.isRequired,
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    subtitle: PropTypes.string
};

export default connect()(CustomPopupBody);
