import * as types from './visitLogTabTypes';
import initialState from './visitLogTabInitialState';
import { fromJS } from 'immutable';

export default function(state = initialState, action) {
    switch (action.type) {
        case types.SET_PERIOD:
            return state.setIn(['visitLogFilter', 'period'], fromJS(action.period));
        case types.SET_VISITS:
            return state.set('visits', fromJS(action.visits));
        default:
            return state;
    }
}