import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './_login.scss';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../controls/FormGroup';
import { defaultFormProperties } from '../../utilities/form';
import * as validationRules from './loginValidations';
import { MuiInputText } from '../controls/MuiInputText';
import loginResources from './loginResources';
import Button from '../../components/controls/Button';
import { setIsForgotPasswordPopupOpen } from '../ForgotPassword/forgotPasswordActions';
import { setIsLoginPopupOpen } from './loginActions';
import { connect } from 'react-redux';

function LoginDataForm({ pristine, submitting, handleSubmit, setIsForgotPasswordPopupOpen, setIsLoginPopupOpen }) {
    const [passwordInputDisabled, setPasswordInputDisabled] = useState(true);
    const forgotPasswordHandler = () => {
        setIsLoginPopupOpen(false);
        setIsForgotPasswordPopupOpen(true);
    };
    return <div className="login__text-fields-wrapper">
        <FormGroup name="loginForm">
            <div className="login__text-fields">
                <Field
                    id="user_name"
                    name={loginResources.fieldNameUserName}
                    component={MuiInputText}
                    label={loginResources.labelUsernameOrEmail}
                    validate={[
                        validationRules.userNameOrEmailRequired
                    ]}
                    addAsteriskToLabel
                    onFocus={() => setPasswordInputDisabled(false)}
                />
                <Field
                    id="password"
                    name={loginResources.fieldNamePassword}
                    secureTextEntry
                    component={MuiInputText}
                    label={loginResources.labelPassword}
                    validate={[
                        validationRules.passwordRequired
                    ]}
                    addAsteriskToLabel
                    disabled={passwordInputDisabled} //disable input to prevent autofill
                    onClick={() => setPasswordInputDisabled(false)}
                />
            </div>
            <div className="login__section">
                <Button className="btn_new btn_new--green"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                    type="submit"
                    name="loginButton"
                >
                    {loginResources.loginButtonText}
                </Button>
                <div>
                    <Button
                        className="login__forgot-link"
                        onClick={forgotPasswordHandler}
                        type="button"
                        name="forgotButton"
                    >
                        {loginResources.linkForgotPassword}
                    </Button>
                </div>
            </div>
        </FormGroup>
    </div>;
};

LoginDataForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    setIsForgotPasswordPopupOpen: PropTypes.func.isRequired,
    setIsLoginPopupOpen: PropTypes.func.isRequired
};


const mapDispatchToProps = {
    setIsForgotPasswordPopupOpen,
    setIsLoginPopupOpen
};

const connectStateToProps = connect(null, mapDispatchToProps);

export default reduxForm({
    form: loginResources.loginDataForm,
    ...defaultFormProperties
})(connectStateToProps(LoginDataForm));
