import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import classNames from 'classnames';
import FormGroup from '../../../components/controls/FormGroup';
import MuiInputSelect from '../../../components/controls/MuiInputSelect';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import MuiInfoBox from '../../../components/InfoBox/MuiInfoBox';
import partnerBillingInfoResources, { PartnerBankAccountTypeOptions } from './partnerBillingInfoResources';
import Button from '../../../components/controls/Button';
import { MuiRadioGroup } from '../../../components/controls/MuiRadioGroup';
import WarningBox from '../../../components/WarningBox/WarningBox';
import sharedResources from '../../Shared/resources';
import { defaultFormProperties } from '../../../utilities/form';
import selectors from './partnerBillingInfoSelectors';
import * as partnerBillingValidationRules from './partnerBillingInfoValidations';
import * as bankingValidationRules from '../../Shared/BankingInfo/validate';
import { getBankAccountDetails } from './partnerBillingInfoActions';

function PartnerBillingInfoForm({ pristine, submitting, handleSubmit, paymentTypesList, isWithdrawalInfoVisible, payeeToken, getBankAccountDetails, accountNumber, routingNumber }) {
    const retrievePartnerBankAccountDetails = () => {
        getBankAccountDetails(payeeToken);
    };
    return <FormGroup onSubmit={handleSubmit}>
        <div className="grid__row align-items-center">
            <div className="col-md-6">
                <Field
                    id="payment_type"
                    labelId="payment_type_label"
                    name={partnerBillingInfoResources.fieldPartnerBillingTypeID}
                    component={MuiInputSelect}
                    label={partnerBillingInfoResources.labelBillingType}
                    options={paymentTypesList}
                    getOptionLabel={option => option.get('name')}
                    getOptionValue={option => option.get('id')}
                    validate={[
                        partnerBillingValidationRules.partnerBillingTypeRequired
                    ]}
                />
            </div>
            <div className="col-md-2">
                <Button
                    type="submit"
                    disabled={pristine || submitting}
                    theme="new btn_new--green"
                    className="no-margin"
                >
                    {sharedResources.saveButtonText}
                </Button>
            </div>
        </div>
        {
            isWithdrawalInfoVisible ?
                <MuiInfoBox
                    title={partnerBillingInfoResources.titleWithdrawalFromBankingInfo}
                    bordered
                    className="height-auto"
                >
                    <div className="partner-billing-info__withdrawal-info">
                        <WarningBox title={partnerBillingInfoResources.warningBoxTitle} description={partnerBillingInfoResources.warningBoxDescription} />
                        <div className="grid__row">
                            <div className={
                                classNames([
                                    { 'col-md-9': payeeToken },
                                    { 'col-md-12': !payeeToken }
                                ])
                            }
                            >
                                <Field
                                    label={partnerBillingInfoResources.labelPayeeToken}
                                    name={`bankAccount.${partnerBillingInfoResources.fieldPayeeToken}`}
                                    component={MuiInputText}
                                    readOnly
                                    masked
                                />
                                {
                                    routingNumber && accountNumber ?
                                        (
                                            <div className="partner-billing-info__banking-account-details">
                                                <div className="partner-billing-info__banking-account-details-row">
                                                    <span className="partner-billing-info__banking-account-details-label">{partnerBillingInfoResources.labelRoutingNumber}:</span>
                                                    <span className="partner-billing-info__banking-account-details-value">{routingNumber}</span>
                                                </div>
                                                <div className="partner-billing-info__banking-account-details-row">
                                                    <span className="partner-billing-info__banking-account-details-label">{partnerBillingInfoResources.labelAccountNumber}:</span>
                                                    <span className="partner-billing-info__banking-account-details-value">{accountNumber}</span>
                                                </div>
                                            </div>
                                        )
                                        : undefined
                                }
                            </div>
                            {
                                payeeToken ?
                                    (
                                        <div className="col-md-3">
                                            <Button
                                                theme="new btn_new--white btn_new--small"
                                                className="partner-billing-info__retrieve-button"
                                                type="button"
                                                onClick={retrievePartnerBankAccountDetails}
                                                disabled={routingNumber && accountNumber}
                                            >
                                                {partnerBillingInfoResources.buttonRetrieve}
                                            </Button>
                                        </div>
                                    )
                                    : undefined
                            }
                        </div>
                        <div className="grid__row">
                            <div className="col-md-6">
                                <Field
                                    id="routing_number"
                                    name={`bankAccount.${partnerBillingInfoResources.fieldRoutingNumber}`}
                                    component={MuiInputText}
                                    label={partnerBillingInfoResources.labelRoutingNumber}
                                    validate={[
                                        bankingValidationRules.routingNumberRequired,
                                        bankingValidationRules.routingNumberNumeric,
                                        bankingValidationRules.routingNumberLength,
                                        bankingValidationRules.routingNumberSpecialCombination
                                    ]}
                                />
                            </div>
                            <div className="col-md-6">
                                <Field
                                    id="account_number"
                                    name={`bankAccount.${partnerBillingInfoResources.fieldAccountNumber}`}
                                    component={MuiInputText}
                                    label={partnerBillingInfoResources.labelAccountNumber}
                                    validate={[
                                        bankingValidationRules.accountNumberRequired,
                                        bankingValidationRules.accountNumberNumericWithWildcard,
                                        bankingValidationRules.accountNumberLength
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="grid__row">
                            <div className="col-md-12">
                                <Field
                                    component={MuiRadioGroup}
                                    label={partnerBillingInfoResources.labelAccountType}
                                    name={`bankAccount.${partnerBillingInfoResources.fieldBankAccountType}`}
                                    options={PartnerBankAccountTypeOptions}
                                    validate={[
                                        bankingValidationRules.accountTypeRequired
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </MuiInfoBox>
                : undefined
        }
    </FormGroup>;
}

PartnerBillingInfoForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    paymentTypesList: PropTypes.object,
    isWithdrawalInfoVisible: PropTypes.bool.isRequired,
    payeeToken: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
    getBankAccountDetails: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getBankAccountDetails
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    form: partnerBillingInfoResources.formPartnerBillingInfo,
    ...defaultFormProperties
})(connectStateToProps(PartnerBillingInfoForm));
