import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import ExcelImage from '../../../components/Image/ExcelImage/ExcelImage';
import InputSelect from '../../../components/controls/InputSelect';
import { Field, reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../utilities/form';
import FormGroup from '../../../components/controls/FormGroup';
import validate from './partnerGroupMembershipPaymentsValidate';
import partnerGroupMemberhipsPaymentsResources from './partnerGroupMemberhipsPaymentsResources';
import Button from '../../../components/controls/Button';

function PartnerGroupMembershipsPaymentsFiltersForm({ handleSubmit, submitFailed, periods, error, onExport } ) {
    return (
        <FormGroup onSubmit={handleSubmit}>
            <div>
                <div className="grid__row">
                    <div className="col-xs-12 col-md-4">
                        <Field
                            component={InputSelect}
                            label={partnerGroupMemberhipsPaymentsResources.labelStartVisitMonth}
                            name="periodFrom"
                            options={periods}
                            getOptionLabel={option => option.get('yearMonth')}
                            getOptionValue={option => option.get('id')}
                            hideValidationMessage
                        />
                    </div>
                </div>
                <div className="grid__row">
                    <div className="col-xs-12 col-md-4">
                        <Field
                            component={InputSelect}
                            label={partnerGroupMemberhipsPaymentsResources.labelEndVisitMonth}
                            name="periodTo"
                            options={periods}
                            getOptionLabel={option => option.get('yearMonth')}
                            getOptionValue={option => option.get('id')}
                            hideValidationMessage
                        />
                    </div>
                </div>
                {error && <div className="validation-summary validation-summary--no-margin">{error}</div>}
                <div className="grid__row">
                    <div className="col-xs-12 col-md-8">
                        <Button
                            size="medium"
                        >
                            {partnerGroupMemberhipsPaymentsResources.buttonShowPayments}
                        </Button>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <ExcelImage
                            onClick={handleSubmit((values) => {
                                onExport(values);
                            })}
                        />
                    </div>
                </div>
            </div>
        </FormGroup>
    );
}

PartnerGroupMembershipsPaymentsFiltersForm.propTypes = {
    submitFailed: PropTypes.bool,
    periods: PropTypes.instanceOf(List),
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired
};

export default reduxForm({
    validate,
    ...defaultFormProperties,
})(PartnerGroupMembershipsPaymentsFiltersForm);