import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import InputFile from '../../../components/controls/InputFile';
import Button from '../../../components/controls/Button';
import { defaultFormProperties } from '../../../utilities/form';
import InfoBox from '../../../components/InfoBox/InfoBox';
import uploadRecipientsFormResources from './uploadRecipientsFormResources';
import * as validationRules from './uploadRecipientsFormValidation';
import resources from '../../Shared/resources';

const UploadRecipientsForm = ({
    pristine,
    submitting,
    handleSubmit,
    match,
    history
}) => {
    return (
        <FormGroup onSubmit={handleSubmit}>
            <div className="grid__row">
                <div className="col-lg-6">
                    <InfoBox title={uploadRecipientsFormResources.headerUploadRecipients} bordered>
                        <Field
                            label={uploadRecipientsFormResources.labelRecipientsFile}
                            name="recipientsFile"
                            component={InputFile}
                            validate={[
                                validationRules.recipientsFileRequired
                            ]}
                        />
                    </InfoBox>
                </div>
            </div>
            <div className="grid__row">
                <div className="col-xs-12 col-md-3 col-lg-2">
                    <Button
                        size="medium"
                        type="submit"
                        disabled={pristine || submitting}
                    >
                        {resources.uploadButtonText}
                    </Button>
                </div>
            </div>
        </FormGroup>
    );
};

UploadRecipientsForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'uploadRecipientsForm',
    ...defaultFormProperties
})(UploadRecipientsForm);
