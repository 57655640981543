import { fromJS } from 'immutable';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import paymentTypesEnum from '../../../enums/paymentTypes';

export default fromJS({
    payments: [],
    partners: [],
    filters: {
        programTypeID: programPaymentTypesEnum.partnerMemberPaid,
        paymentTypeID: paymentTypesEnum.gmMemberDues,
        partnerID: undefined,
        periodID: undefined
    },
    popupData: {
        pin: undefined
    }
});