import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, FormHelperText } from '@material-ui/core';
import './_mui-radio-group.scss';

export function MuiRadioGroup({ label, options, input, meta }) {
    const { value, name, onChange } = input;
    const { error, touched } = meta;
    const showError = (error && touched) || false;
    const labelId = `${label}-label`;
    const stringValue = useMemo(() => {
        if (value !== null || value !== undefined) return input.value.toString();
        return value;
    }, [value]);
    return <FormControl className="mui-radio-group" error={showError}>
        <FormLabel id={labelId}>{label}</FormLabel>
        <RadioGroup
            aria-labelledby={labelId}
            name={name}
            value={stringValue}
            onChange={onChange}
            row
        >
            {
                options.map(option =>
                    <FormControlLabel
                        key={option.id}
                        control={<Radio />}
                        {...option}
                    />
                )
            }
        </RadioGroup>
        <FormHelperText variant="outlined">{showError ? error : ''}</FormHelperText>
    </FormControl>;
}

MuiRadioGroup.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};