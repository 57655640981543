import React, { useEffect } from 'react';
import MetricsSection from '../../../../components/MetricsSection/MetricsSection';
import MetricsSectionItem from '../../../../components/MetricsSection/MetricsSectionItem';
import SectionsDivider from '../../../../components/SectionsDivider/SectionsDivider';
import PartnersSection from '../../../../components/PartnerSection/PartnersSection';
import PartnersSectionItem from '../../../../components/PartnerSection/PartnersSectionItems';
import CallToAction from '../../../../components/CallToAction/CallToAction';
import CallToActionBody from '../../../../components/CallToAction/CallToActionBody';
import CallToActionImage from '../../../../components/CallToAction/CallToActionImage';
import guestAreaSharedResources from '../guestAreaSharedResources';
import { API_PATHS } from '../../../../http/configs';
import TestimonialSection from '../../../../components/TestimonialSection/TestimonialSection';
import TestimonialSectionItem from '../../../../components/TestimonialSection/TestimonialSectionItem';
import corvallis from '../../../../assets/images/corvallis.svg';
import PropTypes from 'prop-types';
import ContactUsSection from '../ContactUs/ContactUsSection';
import { GYM_PARTNER_IMAGES, CORVALLIS_ALT_TAG } from '../../../../constants/partnerImageGroups';
import MetaData from '../../../../components/MetaData/MetaData';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';
import { HC_PHONE_NUMBER } from '../../../../constants/phones';
import '../../../../styles/_public-page.scss';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';

function ForFacilities({ history }) {
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle('For Facilities');
    }, []);
    function joinUsHandler() {
        window.location = API_PATHS.facilityEnrollment;
    };
    return <div className="public-page">
        <MetaData keywords="for facilities"
            title={`For Facilities, ${HEALTHY_CONTRIBUTIONS_NAME}: ${HC_PHONE_NUMBER}`}
            description={`Join our commitment to improving the health & wellness of members, employees, and communities with ${HEALTHY_CONTRIBUTIONS_NAME} fitness & wellness programs`}
        />
        <CallToAction>
            <CallToActionBody subtitle={guestAreaSharedResources.callToActionSubtitle}
                title={<>Join Healthy Contributions’ growing fitness facility network </>}
                description={'Our growing network of national partners includes businesses, organizations, health plans, and individuals who share our commitment to improving the health and well-being of their employees, members, and communities. For fitness facilities, our partners benefit from exclusive resources, marketing materials, a dedicated customer support team, and more to help maximize revenue and build their membership base.\n\nJoin our wellness community. Getting started is easy!'}
                buttonText={guestAreaSharedResources.signUpButtonText}
                buttonClickHandler={joinUsHandler}
            />
            <CallToActionImage image="fitnessGroup1"
                imageAlt={guestAreaSharedResources.signUpTopImageAlt}
                imageClassName="fitness-group1"
            />
        </CallToAction>
        <SectionsDivider />
        <MetricsSection header={<>Build your brand and <span className="green">grow your revenue</span></>}
            description="For fitness facilities, as a member of our wellness network you will have access to hard-to-reach employees and health plan members who have wellness dollars to invest in a healthier lifestyle."
            columns={2}
        >
            <MetricsSectionItem header="17k+ Fitness network partners and growing "
                description="Reach thousands of potential new members by joining our community of fitness partners committed to promoting healthier and happier lifestyles for their members"
            />
            <MetricsSectionItem header="Grow your memberships and increase your revenue"
                description="Participate in a wide variety of employer- and health plan-sponsored fitness incentive programs, all in one place. Facilities can expect to see an average of 55 more members than those that do not participate in Healthy Contributions programs"
            />
            <MetricsSectionItem header="No-cost marketing channel "
                description="Spend more time building relationships with your members. For facilities, our platform helps boost your brand visibility, drive new leads, and higher quality, paid, guest traffic to your locations"
            />
            <MetricsSectionItem header="Secure and HIPPA compliant platform "
                description="Rest easy when it comes to protecting your member data. Our expertise in data security and HIPPA compliance makes us a trusted partner in running your wellness programs"
            />
        </MetricsSection>
        <SectionsDivider />
        <TestimonialSection header={<>Testimonials</>}>
            <TestimonialSectionItem
                description="Our Planet Fitness franchise has been partnering with Healthy Contributions to provide their Renew Active and One Pass members access to our facilities for a number of years now, and our experience with this partnership has been excellent. It allows for us to appeal to their members to obtain additional incremental revenue that we would not receive otherwise based on their awesome reimbursement model. We continue to see financial growth with this program at our locations year after year. Also, their customer service is top notch! Our franchise truly values the partnership, the programs and their members, and plan to continue this relationship for many years to come."
                name="Brandon Sturgill"
                position="Planet Fitness: Director of Marketing and Corporate Partnerships"
            />
            <TestimonialSectionItem name="Sarah Sullivan"
                position="Senior Administrative Specialist: Corvallis Community Center; Corvallis, OR"
                description="Working with Healthy Contributions is a wonderful experience. Their customer service is excellent toward us as a fitness facility, and for their members who are constantly remarking on how smooth the whole process is for them."
                logoName={corvallis}
                logoAlt={CORVALLIS_ALT_TAG}
            />
            <TestimonialSectionItem name="Lisa Becks"
                position="Owner, Anytime Fitness Gainesville, FL"
                description="Love working with Healthy Contributions and highly recommend it to any location currently not using the service!"
            />
            <TestimonialSectionItem name="Chris Thomas"
                position="Multi-Club Owner, Anytime Fitness, PA"
                description="Great service, seamless processes, extra revenue stream with little effort!"
            />
            <TestimonialSectionItem name="Angi Swift"
                position="Manager, Anytime Fitness, Floyd Knobs, IN"
                description="I have utilized your services for the past six years and I love it! The customer service reps are always well-educated, prepared and helpful!"
            />
            <TestimonialSectionItem name="Jen James"
                position="Manager, Anytime Fitness, Placerville, CA"
                description="I have worked with anytime fitness for 9 years. Healthy contributions has been a great company to partner with. Your system is efficient, you always keep us up to date with any changes, you respond quickly to inquiries. I think it’s been a pleasure working with healthy contributions."
            />
        </TestimonialSection>
        <SectionsDivider />
        <PartnersSection header={<>Our <span className="green">Partners</span></>}>
            {GYM_PARTNER_IMAGES.map((imageInfo, index) =>
                <PartnersSectionItem key={index}
                    alt={imageInfo.alt}
                    imageObject={imageInfo.imageName}
                    link={imageInfo.link}
                />
            )
            }
        </PartnersSection>
        <SectionsDivider />
        <ContactUsSection history={history}>
            <CallToActionImage image={'fitnessPark'}
                imageAlt={guestAreaSharedResources.contactUsBottomImageAlt}
                imageClassName={'fitness-park'}
            />
        </ContactUsSection>
        <SectionsDivider />
    </div>;
}

ForFacilities.propTypes = {
    history: PropTypes.object.isRequired
};

export default ForFacilities;