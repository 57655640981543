import { fromJS } from 'immutable';

export default fromJS({
    membersAFFacilityWithinProgramEnrollment: {
        facilityMemberID: undefined,
        isMemberAgreed: false,
        firstName: undefined,
        lastName: undefined,
        contactNumber: undefined,
        email: undefined,
        date: undefined,
        documentAgreementVersion: 4,
        programPaymentType: undefined,
    }
});