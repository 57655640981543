import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../../components/controls/FormGroup';
import { defaultFormProperties } from '../../../../utilities/form';
import { MuiInputText } from '../../../../components/controls/MuiInputText';
import { MuiTextArea } from '../../../../components/controls/MuiTextArea';
import MuiInputSelect from '../../../../components/controls/MuiInputSelect';
import { connect } from 'react-redux';
import contactUsResources from './contactUsResources';
import './_contact-us.scss';
import Captcha from '../../../../components/Captcha/Сaptcha';
import Button from '../../../../components/controls/Button';
import * as validationRules from './contactUsValidations';
import { sendContactUsNotification, getUserDescriptionList } from './contactUsActions';
import selectors from './contactUsSelectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

function ContactUsForm({ pristine, submitting, handleSubmit, sendContactUsNotification, userDescriptions, getUserDescriptionList }) {
    const { useCaptcha } = useFlags();
    useEffect(() => {
        getUserDescriptionList();
    }, []);
    const captchaRef = useRef(null);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const changeCaptchaHandler = (captchaToken) => {
        const isValid = captchaToken != null;
        setIsCaptchaValid(isValid);
        setCaptchaToken(captchaToken);
    };
    const sendHandler = (data) => {
        sendContactUsNotification(data, captchaToken).finally(() => {
            if (captchaRef.current != null) {
                captchaRef.current.reset();
                setIsCaptchaValid(false);
            }
        });
    };
    return <FormGroup onSubmit={handleSubmit(sendHandler)}>
        <div className="contact-us__connect-form-fields">
            <Field
                id="full_name"
                name={contactUsResources.fieldFullName}
                component={MuiInputText}
                label={contactUsResources.labelFullName}
                validate={[
                    validationRules.fullNameRequired,
                    validationRules.fullNameMaxLength
                ]}
                addAsteriskToLabel
            />
            <Field
                id="company_name"
                name={contactUsResources.fieldCompanyName}
                component={MuiInputText}
                label={contactUsResources.labelCompanyName}
                validate={[
                    validationRules.companyNameMaxLength
                ]}
            />
            <Field
                id="email_address"
                name={contactUsResources.fieldEmailAddress}
                component={MuiInputText}
                label={contactUsResources.labelEmailAddress}
                validate={[
                    validationRules.emailAddressRequired,
                    validationRules.emailAddressMaxLength,
                    validationRules.emailAddressRegex
                ]}
                addAsteriskToLabel
            />
            <Field
                id="contact_number"
                name={contactUsResources.fieldContactNumber}
                component={MuiInputText}
                label={contactUsResources.labelContactNumber}
                validate={[
                    validationRules.contactNumberRequired,
                    validationRules.contactNumberMaxLength,
                    validationRules.contactNumberRegex
                ]}
                addAsteriskToLabel
            />
            <Field
                id="user_description"
                labelId="user_description_label"
                name={contactUsResources.fieldSelfDescription}
                component={MuiInputSelect}
                label={contactUsResources.labelSelfDescription}
                options={userDescriptions}
                getOptionLabel={option => option.get('value')}
                getOptionValue={option => option.get('key')}
                validate={[
                    validationRules.descriptionRequired
                ]}
                addAsteriskToLabel
            />
            <Field
                id="message"
                name={contactUsResources.fieldMessage}
                component={MuiTextArea}
                label={contactUsResources.labelMessage}
                validate={[
                    validationRules.messageRequired,
                    validationRules.messageMaxLength
                ]}
                addAsteriskToLabel
            />
        </div>
        {
            useCaptcha && <div className="contact-us__captcha">
                <Captcha changeCaptchaHandler={changeCaptchaHandler} captchaRef={captchaRef} />
            </div>
        }
        <Button className="contact-us__button" theme="new btn_new--green"
            disabled={pristine || submitting || (useCaptcha && !isCaptchaValid)}
        >
            {contactUsResources.sendButtonText}
        </Button>
    </FormGroup>;
};

ContactUsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    sendContactUsNotification: PropTypes.func,
    getUserDescriptionList: PropTypes.func,
    userDescriptions: PropTypes.object
};


const mapDispatchToProps = {
    sendContactUsNotification,
    getUserDescriptionList
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    form: contactUsResources.contactUsDataForm,
    ...defaultFormProperties
})(connectStateToProps(ContactUsForm));
