import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../../components/controls/FormGroup';
import { defaultFormProperties } from '../../../../utilities/form';
import * as validationRules from './nominateClubValidations';
import { MuiInputText } from '../../../../components/controls/MuiInputText';
import nominateClubResources from './nominateClubResources';
import MuiInputSelect from '../../../../components/controls/MuiInputSelect';
import selectors from './nominateClubSelectors';
import { getStatesList } from '../../../../features/States/stateActions';
import Button from '../../../../components/controls/Button';
import { connect } from 'react-redux';
import { nominate } from './nominateClubAction';
import './_nominateClub.scss';
import Captcha from '../../../../components/Captcha/Сaptcha';
import Spinner from '../../../../components/Spinner/Spinner';
import { useFlags } from 'launchdarkly-react-client-sdk';

function ClubInfoDataForm({ pristine, submitting, handleSubmit, states, isLoading, getStates, onPrevStep, nominate, scrollToTop }) {
    const { useCaptcha } = useFlags();
    useEffect(() => {
        getStates();
    }, []);
    const captchaRef = useRef(null);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const changeCaptchaHandler = (captchaToken) => {
        const isValid = captchaToken != null;
        setIsCaptchaValid(isValid);
        setCaptchaToken(captchaToken);
    };
    const nominateClubHandler = (data) => {
        nominate(data, captchaToken).finally(() => {
            if (captchaRef.current !== null) {
                captchaRef.current.reset();
                setIsCaptchaValid(false);
                setCaptchaToken(null);
            }
        });
    };
    return isLoading ?
        <div>
            <Spinner />
            {scrollToTop()}
        </div>
        : <div>
            <div className="nominate__step-info-text">{nominateClubResources.clubInfoStepText}</div>
            <FormGroup onSubmit={handleSubmit(nominateClubHandler)}>
                <div className="nominate__text-fields">
                    <Field
                        id="club_name"
                        name={nominateClubResources.fieldClubName}
                        component={MuiInputText}
                        label={nominateClubResources.labelClubName}
                        validate={[
                            validationRules.clubNameRequired,
                            validationRules.clubNameMaxLength
                        ]}
                        addAsteriskToLabel
                    />
                    <div className="nominate__row">
                        <div className="nominate__row-field">
                            <Field
                                id="city"
                                name={nominateClubResources.fieldCity}
                                component={MuiInputText}
                                label={nominateClubResources.labelCity}
                                validate={[
                                    validationRules.cityRequired,
                                    validationRules.cityMaxLength
                                ]}
                                addAsteriskToLabel
                            />
                        </div>
                        <div className="nominate__row-field">
                            <Field
                                id="state"
                                name={nominateClubResources.fieldState}
                                component={MuiInputSelect}
                                label={nominateClubResources.labelState}
                                options={states}
                                getOptionLabel={option => option.get('name')}
                                getOptionValue={option => option.get('abbr')}
                                validate={[
                                    validationRules.clubStateRequired
                                ]}
                                isSearchable
                                addAsteriskToLabel
                            />
                        </div>
                    </div>
                    <Field
                        id="contact_name"
                        name={nominateClubResources.fieldContactName}
                        component={MuiInputText}
                        label={nominateClubResources.labelContactName}
                        validate={[
                            validationRules.contactNameMaxLength
                        ]}
                    />
                    <div className="nominate__row">
                        <div className="nominate__row-field">
                            <Field
                                id="phone"
                                name={nominateClubResources.fieldPhone}
                                component={MuiInputText}
                                label={nominateClubResources.labelPhone}
                                validate={[
                                    validationRules.phoneRegex
                                ]}
                            />
                        </div>
                        <div className="nominate__row-field">
                            <Field
                                id="club_email"
                                name={nominateClubResources.fieldClubEmail}
                                component={MuiInputText}
                                label={nominateClubResources.labelClubEmail}
                                validate={[
                                    validationRules.clubEmailRegex,
                                    validationRules.clubEmailMaxLength
                                ]}
                            />
                        </div>
                    </div>
                </div>
                {
                    useCaptcha && <div className="nominate__captcha">
                        <Captcha changeCaptchaHandler={changeCaptchaHandler} captchaRef={captchaRef} />
                    </div>
                }
                <div className="nominate__section--buttons">
                    <Button className="btn_new btn_new--white"
                        onClick={onPrevStep}
                        type="button"
                    >
                        {nominateClubResources.linkButtonPreviousText}
                    </Button>
                    <Button className="btn_new btn_new--green"
                        disabled={pristine || submitting || (useCaptcha && !isCaptchaValid)}
                        type="submit"
                    >
                        {nominateClubResources.nominateButtonSubmitText}
                    </Button>
                </div>
            </FormGroup>
        </div>;
};

ClubInfoDataForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    states: PropTypes.object.isRequired,
    getStates: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    nominate: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    nominate,
    getStates: getStatesList
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    ...defaultFormProperties,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connectStateToProps(ClubInfoDataForm));