import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import selectors from './selectors';
import actions from './actions';
import NavigationTabControl from '../../components/NavigationTabControl/NavigationTabControl';
import Spinner from '../../components/Spinner/Spinner';
import withMemberGroup from '../../hoc/withMemberGroup';
import ConditionalRedirect from '../../routing/conditionalRedirect';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';

class AccountInfo extends Component {
    render() {
        const { filteredRoutes, currentRoute, isBankingRequired, isReimbursementEnabled } = this.props;

        return this.props.isLoading ?
            (
                <div className="account-info">
                    <div className="grid__row">
                        <Spinner />
                    </div>
                </div>
            )
            : (
                <div className="account-info">
                    <h2 className="layout__title">Account Information</h2>
                    <NavigationTabControl
                        tabs={filteredRoutes}
                        onNavigate={this.props.history.push}
                    />
                    <Switch>
                        {filteredRoutes.map((route, i) => (
                            <CustomRoute
                                key={i}
                                path={route.path}
                                currentRoute={route}
                                filteredRoutes={filteredRoutes}
                            />
                        ))}

                        <ConditionalRedirect
                            condition={isBankingRequired !== undefined || isReimbursementEnabled !== undefined}
                            fromRoute={currentRoute}
                            toRoute={filteredRoutes[0]}
                        />
                    </Switch>
                </div>
            );
    }
}

AccountInfo.propTypes = {
    checkBankingRequired: PropTypes.func,
    isBankingRequired: PropTypes.bool,
    checkReimbursementEnabled: PropTypes.func,
    isReimbursementEnabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    filteredRoutes: PropTypes.arrayOf(routeType),
    currentRoute: routeType,
    /*ToDo this entity should be of immutable type*/
    selectedMemberGroup: PropTypes.shape({
        fullName: PropTypes.string,
    }),
    allMemberGroups: PropTypes.arrayOf(
        PropTypes.shape({
            fullName: PropTypes.string,
        })
    ),
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};

const withMemberGroupObserved = withMemberGroup({
    actionName: 'doChecks',
})(AccountInfo);

export default connect(
    selectors,
    actions
)(withMemberGroupObserved);
