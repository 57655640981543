import * as types from './partnerPaymentsGenerationSectionTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as partnerPaymentsGenerationSectionService from './partnerPaymentsGenerationSectionService';
import * as partnersListService from '../../Partners/PartnersList/partnersListService';
import * as paymentTypeService from '../../../features/PaymentTypes/paymentTypeService';


const setProgramTypeAction = (programType) => {
    return { type: types.SET_PROGRAM_TYPE, programType };
};

function setProgramType(programType) {
    return function (dispatch) {
        dispatch(setProgramTypeAction(programType));
    };
}

const setProgramPaymentTypeAction = (programPaymentType) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE, programPaymentType };
};

function setProgramPaymentType(programPaymentType) {
    return function (dispatch) {
        dispatch(setProgramPaymentTypeAction(programPaymentType));
    };
}

const setPeriodAction = (period) => {
    return { type: types.SET_PERIOD, period };
};

function setPeriod(period) {
    return function (dispatch) {
        dispatch(setPeriodAction(period));
    };
};

const setCheckPaymentsExistAction = (doPaymentsExist) => {
    return { type: types.SET_PAYMENTS_EXIST, doPaymentsExist };
};

function resetPaymentsExistenceCheck() {
    return function (dispatch) {
        dispatch(setCheckPaymentsExistAction(null));
    };
};

function checkPaymentsExistence(programTypeID, programPaymentTypeID, periodID, partnerID, paymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.CHECK_PAYMENTS_EXISTENCE));
        return partnerPaymentsGenerationSectionService
            .checkPaymentsExist(programTypeID, programPaymentTypeID, periodID, partnerID, paymentTypeID)
            .then((response) => {
                dispatch(setCheckPaymentsExistAction(response.data));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.CHECK_PAYMENTS_EXISTENCE)));
    };
}

function generate(generateParams) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GENERATE_PAYMENTS));
        return partnerPaymentsGenerationSectionService
            .generate(generateParams)
            .finally(() => dispatch(loadingActions.unsetLoading(types.GENERATE_PAYMENTS)));
    };
}

const setPartnersAction = (partners) => {
    return { type: types.SET_PARTNERS, partners };
};

const setIsGenerationWithFeesAction = (isGenerationWithFees) => {
    return { type: types.SET_IS_GENERATION_WITH_FEES, isGenerationWithFees };
};

export function setIsGenerationWithFees(isGenerationWithFees) {
    return function (dispatch) {
        dispatch(setIsGenerationWithFeesAction(isGenerationWithFees));
    };
}

const setIsAdditionalFeesPopupVisibleAction = (isAdditionalFeesPopupVisible) => {
    return { type: types.SET_IS_ADDITIONAL_FEES_POPUP_VISIBLE, isAdditionalFeesPopupVisible };
};

export function setIsAdditionalFeesPopupVisible(isAdditionalFeesPopupVisible) {
    return function (dispatch) {
        dispatch(setIsAdditionalFeesPopupVisibleAction(isAdditionalFeesPopupVisible));
    };
}

export function getPartnersListByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramPaymentType(programPaymentTypeID, null)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

export function getPartnersListByProgramType(programTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramType(programTypeID)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

const setPartnerAction = (partner) => {
    return { type: types.SET_PARTNER, partner };
};

function setPartner(partner) {
    return function (dispatch) {
        dispatch(setPartnerAction(partner));
    };
};

export function getPaymentTypesByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENT_TYPES));
        return paymentTypeService
            .getPaymentTypesByProgramPaymentType(programPaymentTypeID)
            .then((paymentTypes) => {
                dispatch(setPaymentTypesAction(paymentTypes));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PAYMENT_TYPES));
            });
    };
}

const setPaymentTypesAction = (paymentTypes) => {
    return { type: types.SET_PAYMENT_TYPES, paymentTypes };
};

function setPaymentType(paymentType) {
    return function (dispatch) {
        dispatch(setPaymentTypeAction(paymentType));
    };
};

const setPaymentTypeAction = (paymentType) => {
    return { type: types.SET_PAYMENT_TYPE, paymentType };
};

export default {
    setProgramType,
    setProgramPaymentType,
    setPartner,
    setPeriod,
    getPartnersListByProgramPaymentType,
    resetPaymentsExistenceCheck,
    checkPaymentsExistence,
    generate,
    setIsGenerationWithFees,
    setIsAdditionalFeesPopupVisible,
    getPartnersListByProgramType,
    setPaymentType,
    getPaymentTypesByProgramPaymentType
};