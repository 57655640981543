import React from 'react';
import PropTypes from 'prop-types';
import './_text-section.scss';
import classNames from 'classnames';

function TextSection({ title, text, theme }) {
    return (
        <div className={classNames(['text-section text-section__content-container', { [`text-section__content-container--${theme}`] : theme }])} >
            <div className="text-section__title">
                {title}
            </div>
            {text
                ?
                    <div className="text-section__text">
                        {text}
                    </div>
                :
                <></>
        }
        </div>
    );
}
TextSection.propTypes = {
    title: PropTypes.element.isRequired,
    text: PropTypes.string,
    theme: PropTypes.string
};

export default TextSection;