import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import { InputSelect } from '../../../components/controls/InputSelect';
import Table from '../../../components/InfoBox/Table';
import CardInfo from '../../../components/CardInfo/CardInfo';
import selectors from './visitLogTabSelectors';
import visitLogResources from './visitLogTabResources';
import { getClosedUsagePeriods } from '../../Shared/Periods/periodsActions';
import actions from './visitLogTabActions';
import InfoBox from '../../../components/InfoBox/InfoBox';
import ExcelImage from '../../../components/Image/ExcelImage/ExcelImage';

function VisitLogTab({ isLoading, periods, getClosedUsagePeriods, selectedPeriod, setPeriod, getVisits, visits, partnerID, defaultPeriod, downloadVisits }) {
    useEffect(() => {
        getClosedUsagePeriods();
    }, []);
    useEffect(() => {
        setPeriod(defaultPeriod);
    }, [defaultPeriod]);
    useEffect(() => {
        const periodID = selectedPeriod.get('id');
        if (partnerID && periodID) {
            getVisits(partnerID, periodID);
        }
    }, [selectedPeriod]);
    const exportToExcel = () => {
        const periodID = selectedPeriod.get('id');
        if (partnerID && periodID) {
            downloadVisits(partnerID, periodID);
        }
    };
    const columns = useMemo(
        () => [
            {
                Header: visitLogResources.labelKeyfob,
                accessor: 'facilityMemberID'
            },
            {
                Header: visitLogResources.labelFacilityID,
                accessor: 'facilityIDNumber'
            },
            {
                Header: visitLogResources.labelFaciityName,
                accessor: 'facilityName'
            },
            {
                Header: visitLogResources.labelEmployeeFirstName,
                accessor: 'memberFirstName'
            },
            {
                Header: visitLogResources.labelEmployeeLastName,
                accessor: 'memberLastName'
            },
            {
                Header: visitLogResources.labelVisits,
                accessor: 'usagesCount'
            }
        ],
        [visitLogResources]
    );
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <CardInfo>
                <InfoBox>
                    <div className="grid__row">
                        <div className="col-lg-3">
                            <InputSelect
                                label={visitLogResources.labelVisitMonth}
                                value={selectedPeriod}
                                options={periods}
                                getOptionLabel={option => option.get('yearMonth')}
                                getOptionValue={option => option.get('id')}
                                onChange={setPeriod}
                            />
                        </div>
                        <div className="col-lg-9">
                            <ExcelImage onClick={exportToExcel} />
                        </div>
                    </div>
                    <Table
                        columns={columns}
                        data={visits.toJS()}
                    />
                </InfoBox>
            </CardInfo>
        );
}

VisitLogTab.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    periods: PropTypes.object.isRequired,
    getClosedUsagePeriods: PropTypes.func.isRequired,
    selectedPeriod: PropTypes.object.isRequired,
    setPeriod: PropTypes.func.isRequired,
    getVisits: PropTypes.func.isRequired,
    visits: PropTypes.object.isRequired,
    partnerID: PropTypes.number,
    defaultPeriod: PropTypes.object,
    downloadVisits: PropTypes.func.isRequired
};
const mapDispatchToProps = {
    getClosedUsagePeriods,
    ...actions
};

export default connect(selectors, mapDispatchToProps)(VisitLogTab);