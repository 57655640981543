import { createSelector } from 'reselect';

export const getIsLoading = state => state.accountReimbursementHistory.get('isLoading');
export const getReimbursementsGroups = state => state.accountReimbursementHistory.getIn(['reimbursementHistory', 'reimbursementsGroups']);
export const getAvailableSubmissionPeriods = state => state.accountReimbursementHistory.getIn(['reimbursementHistory', 'availableSubmissionPeriods']);
export const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');

export default createSelector(
    [
        getIsLoading,
        getReimbursementsGroups,
        getAvailableSubmissionPeriods,
        getSelectedMemberGroup
    ],
    (
        isLoading,
        reimbursementsGroups,
        availableSubmissionPeriods,
        selectedMemberGroup
    ) => ({
        isLoading,
        reimbursementsGroups,
        availableSubmissionPeriods,
        selectedMemberGroup
    })
);