import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

function FooterMenuItem({ description, active, children }) {
    const defaultClassName = 'footer-menu-item';
    const activeClassName = `${defaultClassName}--active`;
    const [isActive, setActive] = React.useState(active);
    return (
        <li className={classNames([defaultClassName, { [activeClassName]: isActive }])}>
            <div className="footer-menu-item__text" onClick={() => setActive(!isActive)}>
                <label className="footer-menu-item__label">{description}</label>
                <MaterialIcon className="footer-menu-item__expand-icon" icon="expand_more" />
            </div>
            {children}
        </li>
    );
}

FooterMenuItem.propTypes = {
    description: PropTypes.string.isRequired,
    active: PropTypes.bool,
    children: PropTypes.element.isRequired
};

export default FooterMenuItem;