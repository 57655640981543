import {
    GET_REIMBURSEMENT_HISTORY_SUCCESS,
    SET_PLAN_REIMBURSEMENTS_GROUP,
    SET_LOADING,
    UNSET_LOADING,
} from './types';

const setLoading = () => ({
    type: SET_LOADING
});

const unsetLoading = () => ({
    type: UNSET_LOADING
});

const getReimbursementHistorySuccess = reimbursementHistory => ({
    type: GET_REIMBURSEMENT_HISTORY_SUCCESS,
    payload: { reimbursementHistory }
});

const getReimbursementHistory = membersIds => (dispatch, getState, { ReimbursementHistoryService }) => {
    dispatch(setLoading());

    ReimbursementHistoryService.getReimbursementHistory(membersIds)
        .then((reimbursementHistory) => {
            dispatch(getReimbursementHistorySuccess(reimbursementHistory));
            dispatch(unsetLoading());
        })
        .catch(() => {
            dispatch(unsetLoading());
        });
};

const setPlanReimbursementsGroupAction = planReimbursementsGroup => ({
    type: SET_PLAN_REIMBURSEMENTS_GROUP,
    payload: { planReimbursementsGroup }
});

const reloadPlanReimbursementsGroup = planReimbursementsGroup => (dispatch, getState, { ReimbursementHistoryService }) => {
    return ReimbursementHistoryService.getPlanReimbursementsGroup(planReimbursementsGroup)
        .then((planReimbursementsGroup) => {
            const planReimbursementsGroupActionDto = {
                planId: planReimbursementsGroup.planId,
                returns: planReimbursementsGroup.returns,
                submissionPeriodFrom: planReimbursementsGroup.submissionPeriodFrom,
                submissionPeriodTo: planReimbursementsGroup.submissionPeriodTo,
            };

            dispatch(setPlanReimbursementsGroupAction(planReimbursementsGroupActionDto));
        })
        .catch(() => {
        });
};

export default { getReimbursementHistory, reloadPlanReimbursementsGroup };
