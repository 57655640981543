import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import selectors from './selectors';
import actions from './actions';
import Spinner from '../../../components/Spinner/Spinner';
import CardInfo from '../../../components/CardInfo/CardInfo';
import InfoBox from '../../../components/InfoBox/InfoBox';
import BankingInfoForm from '../../Shared/BankingInfo/BankingInfoForm';
import BankingInfoSecurityNote from '../../Shared/BankingInfo/BankingInfoSecurityNote';
import BankingInfoStatus from '../../Shared/BankingInfo/BankingInfoStatus';
import '../../Shared/BankingInfo/_banking-info.scss';
import withMemberGroup from '../../../hoc/withMemberGroup';

class BankingInfo extends Component {
    render() {
        return (
            <div className="banking-info grid__row" id="banking_info">
                {this.props.isLoading ?
                    (
                        <Spinner />
                    )
                    : (
                        <Fragment>
                            <div className="col-md-8">
                                {this.props.bankingInfo.map(
                                    (bankingAccount, index) => {
                                        return (
                                            <CardInfo
                                                key={index}
                                                title={bankingAccount.get(
                                                    'facilityName'
                                                )}
                                                subtitle={bankingAccount.get(
                                                    'facilityAddress'
                                                )}
                                            >
                                                <div className="col-xs-12">
                                                    <BankingInfoStatus
                                                        bankingInfo={bankingAccount}
                                                    />
                                                </div>
                                                <div className="col-xs-12">
                                                    <InfoBox
                                                        title="Deposit To Banking Information"
                                                        bordered
                                                    >
                                                        {
                                                            <BankingInfoForm
                                                                form={`bankingInfo_${index}`}
                                                                initialValues={
                                                                    bankingAccount
                                                                }
                                                                onSubmit={
                                                                    this.props
                                                                        .submit
                                                                }
                                                            />
                                                        }
                                                    </InfoBox>
                                                </div>
                                            </CardInfo>
                                        );
                                    }
                                )}
                            </div>
                            <div className="col-md-4">
                                <BankingInfoSecurityNote />
                            </div>
                        </Fragment>
                    )}
            </div>
        );
    }
}

BankingInfo.propTypes = {
    selectedMemberGroup: PropTypes.shape({
        fullName: PropTypes.string
    }),
    getBankingInfo: PropTypes.func,
    submit: PropTypes.func,
    isLoading: PropTypes.bool,
    bankingInfo: PropTypes.instanceOf(List),
    allMemberGroups: PropTypes.object
};

const withMemberGroupObserved = withMemberGroup({
    actionName: 'getBankingInfo'
})(BankingInfo);

export default connect(selectors, actions)(withMemberGroupObserved);
