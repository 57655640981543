import React, { useState, forwardRef, useRef, useMemo } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import './_search-box.scss';
import ToolTip from '../controls/ToolTip';
import resources from '../../features/Shared/resources';
import { Badge } from '@material-ui/core';

const SearchBox = forwardRef(function SearchBox({
    placeholder, onChange = () => { }, className, showFilterIcon, onShowFilter, filterTooltip, hasFilterSelection }, externalRef) {
    const [clearIconVisible, setClearIconVisible] = useState(false);
    const ref = externalRef || useRef(null);
    const emptyString = '';
    const onChangeDebounced = useMemo(
        () => debounce(onChange, 400), []);

    return <div className={`search-box ${className}`}>
        <span className="search-box__search-icon-container">
            <MaterialIcon className="search-box__search-icon" icon="search" />
        </span>
        <input className="search-box__search-input ellipsis"
            placeholder={placeholder} type="text"
            ref={ref}
            onChange={(e) => {
                const hasValue = !!e.target.value;
                if (hasValue) {
                    setClearIconVisible(true);
                }
                onChangeDebounced(e.target.value);
            }}
        />
        <span
            onClick={() => {
                if (clearIconVisible) {
                    ref.current.value = emptyString;
                    onChangeDebounced(emptyString);
                    ref.current.focus();
                    setClearIconVisible(false);
                }
            }} className="search-box__clear-icon-container"
        >
            {clearIconVisible &&
                <MaterialIcon className="search-box__clear-icon" icon="clear" title={resources.clearButtonText} />}
        </span>
        {showFilterIcon
            && <ToolTip title={filterTooltip} arrow placement="top">
                <Badge variant="dot" invisible={!hasFilterSelection}>
                    <span onClick={onShowFilter} className="search-box__filter-icon-container">
                        <MaterialIcon className="search-box__filter-icon" icon="tune" />
                    </span>
                </Badge>
            </ToolTip>}
    </div>;
});
SearchBox.defaultProps = {
    filterTooltip: 'Filter'
};

SearchBox.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    showFilterIcon: PropTypes.bool,
    onShowFilter: PropTypes.func,
    filterTooltip: PropTypes.string
};

export default SearchBox;