import * as types from './partnerGroupMemberhipsPaymentsTypes';
import initialState from './partnerGroupMemberhipsPaymentsInitialState';
import { fromJS } from 'immutable';

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_PAYMENTS:
            return state.set('payments', fromJS(action.payments));
        case types.SET_USAGE_PERIODS:
            return state.set('usagePeriods', fromJS(action.usagePeriods));
        case types.SET_INITIAL_PERIODS_RANGE:
            return state.set('paymentsFilter', fromJS({
                periodFrom: action.period,
                periodTo: action.period
            }));
        case types.SET_PROGRAM_PAYMENT_TYPE_ID:
            return state.set('programPaymentTypeID', fromJS(action.programPaymentTypeID));
        default:
            return state;
    }
}
