import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './_login.scss';
import { connect } from 'react-redux';
import { setIsLoginPopupOpen, showErrorAlert, setRedirectUrl } from './loginActions';
import CustomPopup from '../Popups/CustomPopup/CustomPopup';
import LoginBody from './LoginBody';
import loginResources from './loginResources';
import selectors from './loginSelectors';
import { useLocation } from 'react-router-dom';
import { IsRedirectToAllowedSite } from '../../utilities/urlHelper';
import { API_PATHS, BASE_URLS } from '../../http/configs';
import { isReactSnap } from '../../utilities/userAgent';

function LoginPopup({ isLoginPopupOpen, setIsLoginPopupOpen, isLoading, showErrorAlert, history, setRedirectUrl }) {
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const showPopup = queryParams.get(loginResources.isLoginPopupOpenParamName) === 'true';
    const error = queryParams.get(loginResources.errorParamName);
    const redirectUrlParamValue = queryParams.get(loginResources.redirectUrlParamName);

    useEffect(() => {
        const redirectUrl = !isReactSnap() && redirectUrlParamValue && IsRedirectToAllowedSite(redirectUrlParamValue, [BASE_URLS.HC_Site])
            ? redirectUrlParamValue
            : API_PATHS.HCDashboard;
        setRedirectUrl(redirectUrl);
        if (showPopup) {
            setIsLoginPopupOpen(showPopup);
        }
        if (error) {
            showErrorAlert(error);
            queryParams.delete(loginResources.errorParamName);
            history.replace({
                search: queryParams.toString(),
            });
        }
    }, []);
    return <div>
        <CustomPopup
            isLoading={isLoading}
            showPopup={isLoginPopupOpen}
            onCancel={() => setIsLoginPopupOpen(false)}
            title={loginResources.title}
            body={<LoginBody closePopup={() => setIsLoginPopupOpen(false)} />}
        />
    </div>;
}

LoginPopup.propTypes = {
    isLoginPopupOpen: PropTypes.bool.isRequired,
    setIsLoginPopupOpen: PropTypes.func.isRequired,
    setRedirectUrl: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showErrorAlert: PropTypes.func,
    history: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    setIsLoginPopupOpen,
    showErrorAlert,
    setRedirectUrl
};

const connectStateToProps = connect(selectors, mapDispatchToProps);
export default connectStateToProps(LoginPopup);