import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_c-textarea.scss';

function TextArea({
    label,
    name,
    id,
    value,
    disabled,
    onChange,
    error,
    touched,
    rows,
    onBlur
}) {
    const showError = error && touched;
    return (
        <div className={classNames(['c-input'])}>
            {label && (
                <label className="c-input__label" htmlFor={id}>
                    {label}
                </label>
            )}
            <div className="c-input__container">
                <textarea
                    className={classNames([
                        'c-input__field c-textarea',
                        { 'input-validation-error': showError },
                    ])}
                    id={id}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                    name={name}
                    rows={rows}
                />
            </div>
            {showError && <div className="field-validation-error">{error}</div>}
        </div>
    );
}

TextArea.defaultProps = {
    onChange: () => { },
    onBlur: () => { },
};

TextArea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    rows: PropTypes.string,
};

export default TextArea;
