import React, { Component } from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import InputSelect from '../../../../components/controls/InputSelect';
import { Field, reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../../utilities/form';
import Button from '../../../../components/controls/Button';
import FormGroup from '../../../../components/controls/FormGroup';
import validate from '../validate';
import resources from '../../../Shared/resources';

class PlanReimbursementsGroupReloadForm extends Component {

    render() {
        const { handleSubmit, availableSubmissionPeriods, error, submitting, submitFailed } = this.props;

        return (
            <FormGroup onSubmit={handleSubmit}>
                <div>
                    <div className="grid__row">
                        <div className="col-xs-12 col-md-4">
                            <Field
                                component={InputSelect}
                                label="Start"
                                inline
                                options={availableSubmissionPeriods}
                                name="submissionPeriodFrom"
                                placeholder="Select Month"
                                getOptionLabel={option => option.get('yearMonth')}
                                getOptionValue={option => option.get('id')}
                                hideValidationMessage
                            />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <Field
                                component={InputSelect}
                                label="End"
                                inline
                                options={availableSubmissionPeriods}
                                name="submissionPeriodTo"
                                placeholder="Select Month"
                                getOptionLabel={option => option.get('yearMonth')}
                                getOptionValue={option => option.get('id')}
                                hideValidationMessage
                            />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <Button
                                size="medium"
                                disabled={submitting}
                            >
                                {resources.showButtonText}
                            </Button>
                        </div>
                    </div>
                    {error && submitFailed && <div className="validation-summary">{error}</div>}
                </div>
            </FormGroup>
        );
    };
}

PlanReimbursementsGroupReloadForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitFailed: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    availableSubmissionPeriods: PropTypes.instanceOf(List),
    error: PropTypes.string
};

export default reduxForm({
    validate,
    ...defaultFormProperties
})(PlanReimbursementsGroupReloadForm);
