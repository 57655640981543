import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../utilities/form';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import MuiButton from '../../../components/controls/MuiButton';
import FormGroup from '../../../components/controls/FormGroup';
import { MuiRadioGroup } from '../../../components/controls/MuiRadioGroup';
import BankingInfoResources, { BankAccountTypeOptions } from './bankingInfoResources';
import {
    routingNumberRequired,
    routingNumberNumeric,
    routingNumberLength,
    routingNumberSpecialCombination,
    accountNumberRequired,
    accountNumberNumericWithWildcard,
    accountNumberLength,
    accountTypeRequired
} from './validate';
import resources from '../../Shared/resources';

function BankingInfoForm({ handleSubmit, submitting, pristine, payeeToken, getBankAccountDetails, accountNumber, routingNumber, isAdmin, initialValues }) {
    const [inputsDisabled, setInputsDisabled] = useState(false);
    const [accountNumberExists, setAccountNumberExists] = useState(false);
    useEffect(() => {
        setInputsDisabled(initialValues.get('bankAccountRequest') &&
            !initialValues.getIn([
                'bankAccountRequest',
                'bankAccountEditingAllowed'
            ]));
        setAccountNumberExists(accountNumber || initialValues.getIn(['bankAccount', 'accountNumber']));
    }, [initialValues, accountNumber]);
    return (
        <FormGroup onSubmit={handleSubmit}>
            {isAdmin
                ?
                    <div className="grid__row">
                        <div className="col-lg-6">
                            <div className="bank-info-section">
                                <label>{BankingInfoResources.payeeTokenLabel}</label>
                                {payeeToken && (
                                    <div className="payee-token-container">
                                        <p>{payeeToken}</p>
                                        <MuiButton
                                            size="medium"
                                            disabled={false}
                                            type="button"
                                            noPadding
                                            onClick={() => getBankAccountDetails(payeeToken)}
                                        >
                                            {BankingInfoResources.retrieveButtonLabel}
                                        </MuiButton>
                                    </div>
                                )}
                                {accountNumberExists && (<div>
                                    <p className="bank-account-item">{BankingInfoResources.routingNumberLabel}: {routingNumber}</p>
                                    <p className="bank-account-item">{BankingInfoResources.accountNumberLabel}: {accountNumber}</p>
                                </div>)}
                            </div>
                        </div>
                    </div>
                :
                <>
                    {accountNumberExists && (
                        <div className="grid__row">
                            <div className="col-lg-12">
                                <span className="bank-info-current-text">{BankingInfoResources.labelCurrentLinkedAccount}</span>
                            </div>
                        </div>
                    )}
                </>
            }
            <div className="grid__row">
                <div className="col-lg-6">
                    <Field
                        id="routing_number"
                        name="bankAccount.routingNumber"
                        label={BankingInfoResources.routingNumberLabel}
                        component={MuiInputText}
                        disabled={inputsDisabled}
                        validate={[
                            routingNumberRequired,
                            routingNumberNumeric,
                            routingNumberLength,
                            routingNumberSpecialCombination
                        ]}
                    />
                </div>
                <div className="col-lg-6">
                    <Field
                        id="account_number"
                        name="bankAccount.accountNumber"
                        label={BankingInfoResources.accountNumberLabel}
                        component={MuiInputText}
                        disabled={inputsDisabled}
                        validate={[
                            accountNumberRequired,
                            accountNumberNumericWithWildcard,
                            accountNumberLength
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-lg-12">
                    <Field
                        component={MuiRadioGroup}
                        label={BankingInfoResources.accountTypeLabel}
                        name="bankAccount.bankAccountType"
                        options={BankAccountTypeOptions}
                        disabled={inputsDisabled}
                        validate={accountTypeRequired}
                    />
                </div>
            </div>
            <div className="grid__row">
                <MuiButton
                    size="medium"
                    disabled={submitting || pristine}
                    type="submit"
                >
                    {resources.updateButtonText}
                </MuiButton>
            </div>
        </FormGroup>
    );
}

BankingInfoForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.instanceOf(Map).isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    payeeToken: PropTypes.string,
    getBankAccountDetails: PropTypes.func,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
    isAdmin: PropTypes.bool
};

export default reduxForm({
    ...defaultFormProperties
})(BankingInfoForm);
