import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function getPartnersList() {
    return http.get(API_PATHS.listPartners);
}

export function getPartnersListByProgramType(programTypeID) {
    return http.get(API_PATHS.listPartnersByProgramType, { params: { programTypeID } });
}

export function getPartnersListByProgramPaymentType(programPaymentTypeId, partnerBillingType) {
    return http.get(replaceRouteParams(API_PATHS.listPartnersByProgramPaymentType, programPaymentTypeId), { params: { partnerBillingType } });
}
