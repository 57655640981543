import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/Image';

function PartnersSectionItem({ imageObject, alt, link }) {
    const image = <Image alt={alt} image={imageObject}/>;
    return <div className="partners-section__item">
        {link ? <a href={link}>{image}</a> : <div>{image}</div>}
    </div>;
}

PartnersSectionItem.propTypes = {
    imageObject: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    link: PropTypes.string
};

export default PartnersSectionItem;