import React from 'react';
import PropTypes from 'prop-types';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import FormGroup from '../../../../components/controls/FormGroup';
import { Field, reduxForm } from 'redux-form/immutable';
import InputText from '../../../../components/controls/InputText';
import Button from '../../../../components/controls/Button';
import { defaultFormProperties } from '../../../../utilities/form';
import addKeyfobsResources from './addKeyfobsResources';
import * as validationRules from './addKeyfobsFormValidations';
import resources from '../../../Shared/resources';

function AddKeyfobsForm({
    pristine,
    submitting,
    handleSubmit
}) {
    return (
        <FormGroup onSubmit={handleSubmit}>
            <div className="grid__row">
                <div className="col-lg-6">
                    <InfoBox title="Add Keyfobs" bordered>
                        <Field
                            label={addKeyfobsResources.labelFirstKeyfob}
                            name="firstKeyfob"
                            component={InputText}
                            validate={[
                                validationRules.firstKeyfobRequired,
                                validationRules.keyfobMinMaxLength,
                                validationRules.firstKeyfobValidRadixNumber,
                                validationRules.keyfobsSameLength,
                                validationRules.firstKeyfobLessThanOrEqual
                            ]}
                        />
                        <Field
                            label={addKeyfobsResources.labelLastKeyfob}
                            name="lastKeyfob"
                            component={InputText}
                            validate={[
                                validationRules.lastKeyfobRequired,
                                validationRules.keyfobMinMaxLength,
                                validationRules.lastKeyfobValidRadixNumber,
                                validationRules.keyfobsSameLength,
                                validationRules.lastKeyfobGreaterThanOrEqual,
                                validationRules.lastKeyfobMaxNumberOfKeyfobs
                            ]}
                        />
                    </InfoBox>
                </div>
            </div>
            <Button
                size="medium"
                disabled={pristine || submitting}
            >
                {resources.addButtonText}
            </Button>
        </FormGroup>
    );
}

AddKeyfobsForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'addKeyfobsForm',
    ...defaultFormProperties
})(AddKeyfobsForm);
