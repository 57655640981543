import React, { useEffect, useState } from 'react';
import App from '../App/App';
import Spinner from '../../components/Spinner/Spinner';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import launchDarklySettings from '../Shared/LaunchDarkly/launchDarklySettings';

function AppWithFlagsSupport() {
    const [LDProvider, setLDProvider] = useState();

    useEffect(() => {
        asyncWithLDProvider(launchDarklySettings)
            .then(provider => setLDProvider(() => provider));
    }, []);
    return (
        LDProvider
            ? <LDProvider>
                <App />
            </LDProvider>
            : <Spinner />
    );
};

export default AppWithFlagsSupport;