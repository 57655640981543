import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Table from '../../../components/InfoBox/Table';
import partnerGroupMemberhipsPaymentsResources from './partnerGroupMemberhipsPaymentsResources';
import actions from './partnerGroupMemberhipsPaymentsActions';
import selectors from './partnerGroupMemberhipsPaymentsSelectors';
import moment from 'moment';
import { MONTH_YEAR } from '../../../constants/formats';
import PartnerGroupMembershipsPaymentsFiltersForm from './PartnerGroupMembershipsPaymentsFiltersForm';
import { useFlags } from 'launchdarkly-react-client-sdk';
import programPaymentTypes from '../../../enums/programPaymentTypes';
import './_partner-group-memberships-payments.scss';

function PartnerGroupMembershipsPayments({
    isLoading,
    getClosedUsagePeriods,
    payments,
    getPayments,
    downloadPayments,
    paymentsFilter,
    usagePeriods,
    partnerId,
    programPaymentTypeID,
    getPeriodsUntilCurrent,
    getProgramPaymentTypeID
}) {
    const { gmrAddProgram } = useFlags();
    const [programPaymentTypeIDLoaded, setProgramPaymentTypeIDLoaded] = useState(false);
    useEffect(() => {
        getProgramPaymentTypeID(partnerId).then(() => { setProgramPaymentTypeIDLoaded(true); });
    }, [partnerId]);
    useEffect(() => {
        if (!programPaymentTypeIDLoaded) {
            return;
        }
        if (programPaymentTypeID === programPaymentTypes.memberPaid || programPaymentTypeID === programPaymentTypes.partnerMemberPaid) {
            getPeriodsUntilCurrent();
        }
        else if (programPaymentTypeID === programPaymentTypes.partnerPaid) {
            getClosedUsagePeriods();
        }
    }, [programPaymentTypeIDLoaded]);
    useEffect(() => {
        if (!programPaymentTypeIDLoaded) {
            return;
        }
        const periodIdFrom = paymentsFilter.getIn(['periodFrom', 'id']);
        const periodIdTo = paymentsFilter.getIn(['periodTo', 'id']);
        if (periodIdFrom && periodIdTo) {
            getPayments(programPaymentTypeID, partnerId, periodIdFrom, periodIdTo);
        }
    }, [paymentsFilter]);

    const gmColumns = useMemo(
        () => [
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelVisitMonth,
                accessor: 'visitPeriodStartDate',
                Cell: cell => moment(cell.value).format(MONTH_YEAR)
            },
            ...(programPaymentTypeID === programPaymentTypes.partnerPaid
                ?
                [{
                    Header: partnerGroupMemberhipsPaymentsResources.labelTotalNumberOfMemberships,
                    accessor: 'totalNumberOfMemberships'
                },
                {
                    Header: partnerGroupMemberhipsPaymentsResources.labelNumberOfActivatedMemberships,
                    accessor: 'numberOfActivatedMemberships'
                }]
                :
                []),
            ...(gmrAddProgram
                ?
                [{
                    Header: partnerGroupMemberhipsPaymentsResources.labelProgramFee,
                    accessor: 'monthlyInstallment',
                    Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
                }]
                :
                [{
                    Header: partnerGroupMemberhipsPaymentsResources.labelMonthlyInstallment,
                    accessor: 'monthlyInstallment',
                    Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
                },
                {
                    Header: partnerGroupMemberhipsPaymentsResources.labelStartupFee,
                    accessor: 'startupFee',
                    Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
                }]),
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelAdminFee,
                accessor: 'adminFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelRenewalFee,
                accessor: 'renewalFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelFacilityAccessFee,
                accessor: 'facilityAccessFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelOneTimeFee,
                accessor: 'oneTimeFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                minWidth: 120
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelOneTimeFeeReason,
                accessor: 'oneTimeFeeReason'
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelTotalPayment,
                accessor: 'totalPayment',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            }
        ],
        [partnerGroupMemberhipsPaymentsResources, programPaymentTypeID]
    );

    const gmspColumns = useMemo(
        () => [
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelVisitMonth,
                accessor: 'visitPeriodStartDate',
                Cell: cell => moment(cell.value).format(MONTH_YEAR)
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelAdminFee,
                accessor: 'adminFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelRegisteredMembers,
                accessor: 'numberOfActivatedMemberships'
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelTotalMonthlyDuesAmount,
                accessor: 'totalPayment',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            }
        ],
        [partnerGroupMemberhipsPaymentsResources]
    );

    const defaultSorted = useMemo(() => [{ id: 'visitPeriodStartDate', desc: true }]);
    const handleSubmit = (paymentsFilterSubmitted) => {
        const paymentsFilterSubmittedJS = paymentsFilterSubmitted.toJS();
        getPayments(programPaymentTypeID, partnerId, paymentsFilterSubmittedJS.periodFrom.id, paymentsFilterSubmittedJS.periodTo.id);
    };
    const exportToExcel = (paymentsFilterSubmitted) => {
        const periodIdFrom = paymentsFilterSubmitted.getIn(['periodFrom', 'id']);
        const periodIdTo = paymentsFilterSubmitted.getIn(['periodTo', 'id']);
        if (periodIdFrom && periodIdTo) {
            downloadPayments(partnerId, periodIdFrom, periodIdTo);
        }
    };
    const formId = 'partnerGroupMembershipsPaymentsFiltersForm';
    return isLoading ?
        (<Spinner />)
        :
        (
            <InfoBox className="partner-group-membership-payments-container" title={partnerGroupMemberhipsPaymentsResources.titlePartnerGroupMemberhipsPayments}
                bordered
            >
                <PartnerGroupMembershipsPaymentsFiltersForm
                    form={formId}
                    periods={usagePeriods}
                    initialValues={paymentsFilter}
                    onSubmit={handleSubmit}
                    onExport={exportToExcel}
                />
                <div className="grid__row">
                    <div className="col-lg">
                        <Table
                            columns={programPaymentTypeID !== programPaymentTypes.partnerMemberPaid ? gmColumns : gmspColumns}
                            data={payments.toJS()}
                            defaultSorted={defaultSorted}
                        />
                    </div>
                </div>
            </InfoBox>
        );
}
PartnerGroupMembershipsPayments.propTypes = {
    partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    usagePeriods: PropTypes.object.isRequired,
    payments: PropTypes.object.isRequired,
    paymentsFilter: PropTypes.object.isRequired,
    getPayments: PropTypes.func.isRequired,
    downloadPayments: PropTypes.func.isRequired,
    getClosedUsagePeriods: PropTypes.func.isRequired,
    programPaymentTypeID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getPeriodsUntilCurrent: PropTypes.func.isRequired,
    getProgramPaymentTypeID: PropTypes.func.isRequired,
};
const mapDispatchToProps = {
    ...actions
};
export default connect(selectors, mapDispatchToProps)(PartnerGroupMembershipsPayments);