import * as types from './partnerPaymentsSectionTypes';
import * as partnerPaymentSectionService from './partnerPaymentsSectionService';
import * as loadingActions from '../../Loading/loadingActions';
import * as partnersListService from '../../Partners/PartnersList/partnersListService';
import * as paymentTypeService from '../../../features/PaymentTypes/paymentTypeService';

const setPayments = (payments) => {
    return { type: types.SET_PAYMENTS, payments };
};

function getPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));
        return partnerPaymentSectionService
            .getPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly)
            .then((payments) => {
                dispatch(setPayments(payments));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS));
            });
    };
}

function downloadPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.DOWNLOAD_PAYMENTS));
        return partnerPaymentSectionService
            .downloadPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly)
            .finally(() => dispatch(loadingActions.unsetLoading(types.DOWNLOAD_PAYMENTS)));
    };
}

const setProgramPaymentTypeAction = (programPaymentType) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE, programPaymentType };
};

function setProgramPaymentType(programPaymentType) {
    return function (dispatch) {
        dispatch(setProgramPaymentTypeAction(programPaymentType));
    };
}

const setPeriodAction = (period) => {
    return { type: types.SET_PERIOD, period };
};

function setPeriod(period) {
    return function (dispatch) {
        dispatch(setPeriodAction(period));
    };
}

const setPartnerAction = (partner) => {
    return { type: types.SET_PARTNER, partner };
};

function setPartner(partner) {
    return function (dispatch) {
        dispatch(setPartnerAction(partner));
    };
}

const setPaymentTypeAction = (paymentType) => {
    return { type: types.SET_PAYMENT_TYPE, paymentType };
};

function setPaymentType(paymentType) {
    return function (dispatch) {
        dispatch(setPaymentTypeAction(paymentType));
    };
}

const setShowCorrectedOnlyAction = (value) => {
    return { type: types.SET_SHOW_CORRECTED_ONLY, value };
};

function setShowCorrectedOnly(value) {
    return function (dispatch) {
        dispatch(setShowCorrectedOnlyAction(value));
    };
}

const updateAmountToBeSentAction = (paymentId, amountToBeSent) => {
    return { type: types.SET_AMOUNT_TO_BE_SENT, paymentId, amountToBeSent };
};

function updatePartnerPaymentAmountToBeSent(programTypeId, programPaymentTypeId, paymentId, amountToBeSent) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));
        return partnerPaymentSectionService
            .updatePartnerPaymentAmountToBeSent(programTypeId, programPaymentTypeId, paymentId, amountToBeSent)
            .then(() => {
                dispatch(updateAmountToBeSentAction(paymentId, amountToBeSent));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS)));
    };
}

const undoAmountCorrectionAction = (paymentId) => {
    return { type: types.UNDO_AMOUNT_CORRECTION, paymentId };
};

function undoPartnerPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));
        return partnerPaymentSectionService
            .undoPartnerPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId)
            .then(() => {
                dispatch(undoAmountCorrectionAction(paymentId));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS)));
    };
}

const setPartnersAction = (partners) => {
    return { type: types.SET_PARTNERS, partners };
};

export function getPartnersListByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramPaymentType(programPaymentTypeID, null)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

export function getPaymentTypesByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENT_TYPES));
        return paymentTypeService
            .getPaymentTypesByProgramPaymentType(programPaymentTypeID)
            .then((paymentTypes) => {
                dispatch(setPaymentTypesAction(paymentTypes));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PAYMENT_TYPES));
            });
    };
}

const setPaymentTypesAction = (paymentTypes) => {
    return { type: types.SET_PAYMENT_TYPES, paymentTypes };
};

export default {
    setProgramPaymentType,
    setPartner,
    getPayments,
    setPeriod,
    setPaymentType,
    downloadPayments,
    updatePartnerPaymentAmountToBeSent,
    undoPartnerPaymentAmountCorrection,
    setShowCorrectedOnly,
    getPartnersListByProgramPaymentType,
    getPaymentTypesByProgramPaymentType
};
