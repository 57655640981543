const forgotPasswordResources = {
    forgotButtonText: 'Send',
    labelUsername: 'Username',
    title: 'Forgot your password',
    fieldNameUsername: 'username',
    forgotPasswordDataForm: 'forgotPasswordDataForm',
    subtitle: 'Please enter your username to receive a link to reset your password.',
    resendButtonText: 'Resend',
    loginButtonText: 'Return to login',
    resultTitle: 'Reset password link sent',
    resultSubtitle: 'An email with a link to reset your password has been sent.',
    paramName: 'isForgotPasswordPopupOpen'
};
export default forgotPasswordResources;