const memberEnrollmentsStatuses = Object.freeze({
    waitingToInvite: 1,
    invited: 2,
    enrolledWaitingForPayment: 3,
    activated: 4,
    suspended: 5,
    waitingToDelete: 6,
    onHold: 7
});

export const memberEnrollmentsStatusesNamesMap = Object.freeze({
    [memberEnrollmentsStatuses.waitingToInvite]: 'Waiting to Invite',
    [memberEnrollmentsStatuses.invited]: 'Invited',
    [memberEnrollmentsStatuses.enrolledWaitingForPayment]: 'Enrolled Waiting For Payment',
    [memberEnrollmentsStatuses.activated]: 'Activated',
    [memberEnrollmentsStatuses.suspended]: 'Suspended',
    [memberEnrollmentsStatuses.waitingToDelete]: 'Waiting to Delete',
    [memberEnrollmentsStatuses.onHold]: 'On Hold'
});

export const memberEnrollmentsStatusesColorsMap = Object.freeze({
    [memberEnrollmentsStatuses.waitingToInvite]: 'yellow',
    [memberEnrollmentsStatuses.invited]: 'green',
    [memberEnrollmentsStatuses.enrolledWaitingForPayment]: 'orange',
    [memberEnrollmentsStatuses.activated]: 'green',
    [memberEnrollmentsStatuses.suspended]: 'beet',
    [memberEnrollmentsStatuses.waitingToDelete]: 'beet',
    [memberEnrollmentsStatuses.onHold]: 'orange'
});

export default memberEnrollmentsStatuses;