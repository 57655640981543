import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import allRoutes from '../../../routing/routes';
import Button from '../../../components/controls/Button';
import InfoBox from '../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../components/InfoBox/InfoBoxRow';
import Table from '../../../components/InfoBox/Table';
import ActionCell from '../../../components/InfoBox/ActionCell';
import selectors from './voucherProgramsListSelectors';
import { getVoucherPrograms } from './voucherProgramsListActions';
import voucherProgramsListResources from './voucherProgramsListResources';
import Spinner from '../../../components/Spinner/Spinner';

function VoucherProgramsList({ match, history, isLoading, getVoucherPrograms, voucherPrograms }) {
    const partnerId = match.params.partnerId;
    useEffect(() => {
        getVoucherPrograms(partnerId);
    }, []);
    const editHandler = (voucherProgramId) => {
        history.push(generatePath(allRoutes.voucherProgramsEdit.path, { partnerId, voucherProgramId }));
    };
    const columns = React.useMemo(
        () => [
            {
                Header: voucherProgramsListResources.programName,
                accessor: 'displayName',
            },
            {
                Header: voucherProgramsListResources.headerActions,
                disableSortBy: true,
                accessor: 'id',
                Cell: ActionCell,
                getProps: (row) => {
                    return ({
                        handler: editHandler,
                        label: row.original.publishedOn === null ? voucherProgramsListResources.labelEdit : voucherProgramsListResources.labelView,
                        theme: row.original.publishedOn === null ? 'blue' : 'white'
                    });
                }
            }
        ],
        []
    );

    return <>
        {isLoading ?
            (
                <Spinner />
            )
            : (<div className="col-lg-6">
                <h2 className="layout__title">{voucherProgramsListResources.headerVoucherPrograms}</h2>
                <InfoBox bordered>
                    <InfoBoxRow>
                        <Button
                            theme="grey"
                            onClick={() => {
                                history.push(generatePath(allRoutes.voucherProgramsCreate.path, { partnerId }));
                            }
                            }
                        >
                            {voucherProgramsListResources.addVoucherProgramButtonText}
                        </Button>
                    </InfoBoxRow>
                    <Table columns={columns} data={voucherPrograms.toJS()} />
                </InfoBox>
            </div>
            )}</>;
}

VoucherProgramsList.propTypes = {
    voucherPrograms: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getVoucherPrograms: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

const actions = {
    getVoucherPrograms
};

export default connect(selectors, actions)(VoucherProgramsList);
