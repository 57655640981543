import { createSelector } from 'reselect';
import { GET_SIGNATURE_MEMBER_INFO } from './legalContractTypes';
import { GET_FACILITY } from '../../../Facilities/facilityTypes';

const getDataLoadingIsInProgress = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_SIGNATURE_MEMBER_INFO || i === GET_FACILITY)
        .size;
    return size > 0;
};

const getFacility = state => state.facilityReducer.get('facility');
const getEnrollIntoAFFacilityWithinProgramViewModel = state => state.legalContractReducer.get('membersAFFacilityWithinProgramEnrollment');

const getFacilityDisplayName = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('displayName');
    }
);

const getFacilityFullAddress = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('fullAddress');
    }
);

const getDocumentAgreementVersion = createSelector(
    [getEnrollIntoAFFacilityWithinProgramViewModel],
    (enrollIntoAFFacilityWithinProgramViewModel) => {
        return enrollIntoAFFacilityWithinProgramViewModel.get('documentAgreementVersion');
    }
);

const getProgramPaymentType = createSelector(
    [getEnrollIntoAFFacilityWithinProgramViewModel],
    (membersAFFacilityWithinProgramEnrollment) => {
        return membersAFFacilityWithinProgramEnrollment.get('programPaymentType');
    }
);

const isLoading = createSelector(
    [
        getDataLoadingIsInProgress,
        getProgramPaymentType
    ],
    (
        dataLoadingIsInProgress,
        programPaymentType
    ) => (
        dataLoadingIsInProgress || programPaymentType === undefined
    ));

export default createSelector(
    [
        isLoading,
        getEnrollIntoAFFacilityWithinProgramViewModel,
        getFacilityDisplayName,
        getFacilityFullAddress,
        getProgramPaymentType,
        getDocumentAgreementVersion
    ],
    (
        isLoading,
        membersAFFacilityWithinProgramEnrollment,
        facilityDisplayName,
        facilityFullAddress,
        programPaymentType,
        documentAgreementVersion
    ) => (
        {
            isLoading,
            membersAFFacilityWithinProgramEnrollment,
            facilityDisplayName,
            facilityFullAddress,
            programPaymentType,
            documentAgreementVersion
        }));

