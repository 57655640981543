import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import FacilityLocatorMap from '../../features/MemberArea/Dashboard/FacilityLocator/FacilityLocatorMap/FacilityLocatorMap';
import { connect } from 'react-redux';
import BulletCaption from '../BulletCaption/BulletCaption';
import { getFacilitiesForMap, getPlansForMap } from './clubLocatorSectionActions';
import selectors from './clubLocatorSectionSelectors';
import clubLocatorResources from './clubLocatorSectionResources';
import './_club-locator-section.scss';
import Button from '../../components/controls/Button';
import routes from '../../routing/routes';

function ClubLocatorSection({ header, history, isLoading, getFacilitiesForMap, facilities, getPlansForMap, checkedPlans }) {
    useEffect(() => {
        getFacilitiesForMap();
        getPlansForMap();
    }, []);

    useEffect(() => {
        if (checkedPlans && checkedPlans.size > 0) {
            getFacilitiesForMap(checkedPlans.toJS());
        }
    }, [checkedPlans]);

    const nominateHandler = () => {
        history.push(
            generatePath(routes.nominateClub.path)
        );
    };
    return <div className="club-locator-section">
        {isLoading ?
            <div>
                <Spinner />
            </div>
            : <></>
        }
        <div className="club-locator-section__header-wrapper">
            <div className="club-locator-section__header">
                {header}
            </div>
            <div className="club-locator-section__map-legend">
                <div className="club-locator-section__map-legend-item" >
                    <BulletCaption size="big" color="blue"
                        text={clubLocatorResources.labelFitnessFacility}
                    />
                </div>
                <div className="club-locator-section__map-legend-item" >
                    <BulletCaption size="big" color="green"
                        text={clubLocatorResources.labelFitnessClass}
                    />
                </div>
                <div className="club-locator-section__map-legend-item" >
                    <BulletCaption size="big" color="beet"
                        text={clubLocatorResources.labelFlexInstructor}
                    />
                </div>
            </div>
        </div>
        <FacilityLocatorMap facilitiesInitial={facilities} history={history}
            isEnrollButtonVisible={false} maxClusterZoom={10}
            clusterSize={30} clusterRadius={100} showFilter
        />
        <div className="club-locator-section_nominate-wrapper">
            <div className="club-locator-section__nominate-description">
                {clubLocatorResources.nominateDescription}
            </div>
            <div className="club-locator-section_nominate-btn-wrapper">
                <Button className="club-locator-section_nominate-btn-wrapper_btn btn_new btn_new--green"
                    onClick={nominateHandler}
                >
                    {clubLocatorResources.buttonText}
                </Button>
            </div>
        </div>
    </div>;
}

ClubLocatorSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    getFacilitiesForMap: PropTypes.func.isRequired,
    facilities: PropTypes.object,
    history: PropTypes.object.isRequired,
    getPlansForMap: PropTypes.func.isRequired,
    checkedPlans: PropTypes.object
};

export default connect(selectors, { getFacilitiesForMap, getPlansForMap })(ClubLocatorSection);