/* eslint-disable react/no-multi-comp*/
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import './_muiinput.scss';
import styles from './_muiinput.module.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';

export function MuiInputText(props) {
    const {
        meta = {},
        input = {},
        label,
        addAsteriskToLabel,
        variant,
        defaultValue,
        id,
        disabled,
        onClick,
        fullWidth,
        secureTextEntry,
        showErrorMessage,
        readOnly,
        startAdornment,
        masked,
        adornmentVariant,
        labelVariant
    } = props;
    const muiLabel = useMemo(() => {
        return addAsteriskToLabel ? `${label} *` : label;
    }, [addAsteriskToLabel]);
    const { value, name, onChange, onBlur, onFocus } = input;
    const { error, touched } = meta;
    const [secureMode, setSecureMode] = useState(secureTextEntry);
    const isNotEmpty = value && value.length;
    const showError = (error && touched) || false;
    const showSecureModeSwitcher = secureTextEntry && isNotEmpty;
    const showHelperText = showError && showErrorMessage;
    const displayValue = useMemo(() => {
        return masked && value
            ? `***${value.slice(-4)}`
            : value;
    }, [value, masked]);
    return <div className="mui-input-container">
        <TextField
            id={id}
            name={name}
            label={muiLabel}
            InputProps={{
                readOnly,
                className: readOnly ? styles.input_readOnly : '',
                endAdornment: showSecureModeSwitcher
                    ? (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setSecureMode(!secureMode)}
                                edge="end"
                            >
                                <MaterialIcon className="visibility-icon" icon={secureMode ? 'visibility' : 'visibility_off'} />
                            </IconButton>
                        </InputAdornment>
                    )
                    : null,
                startAdornment: startAdornment ?
                    (<InputAdornment position="start">{startAdornment} variant={adornmentVariant} className={styles.inputAdornment}</InputAdornment>)
                    : null
            }}
            InputLabelProps= {{
                variant: labelVariant,
                className: readOnly ? styles.label_readOnly : ''
            }}
            error={showError}
            helperText={showHelperText ? error : ''}
            type={secureMode ? 'password' : 'text'}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            disabled={disabled}
            value={displayValue}
            variant={readOnly ? 'filled' : variant}
            fullWidth={fullWidth}
            onFocus={onFocus}
        />
    </div>;
}

MuiInputText.defaultProps = {
    secureTextEntry: false,
    addAsteriskToLabel: false,
    variant: 'outlined',
    fullWidth: true,
    showErrorMessage: true,
    readOnly: false,
    masked: false,
    adornmentVariant: 'outlined',
    labelVariant: 'outlined',
};

MuiInputText.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    secureTextEntry: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    addAsteriskToLabel: PropTypes.bool,
    meta: PropTypes.object,
    input: PropTypes.object,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    onFocus: PropTypes.func,
    showErrorMessage: PropTypes.bool,
    readOnly: PropTypes.bool,
    startAdornment: PropTypes.string,
    masked: PropTypes.bool,
    adornmentVariant: PropTypes.string,
    labelVariant: PropTypes.string
};
