import {
    GET_BANKING_INFO_SUCCESS,
    UPDATE_BANKING_INFO_SUCCESS,
    SET_LOADING,
    UNSET_LOADING
} from './types';
import {
    fromJS
} from 'immutable';
import {
    initialState
} from './initials';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOADING:
            return state.set('isLoading', true);
        case UNSET_LOADING:
            return state.set('isLoading', false);
        case GET_BANKING_INFO_SUCCESS:
            return state.set('bankingInfo', fromJS(action.payload.bankingInfo));
        case UPDATE_BANKING_INFO_SUCCESS: {
            const specificBankingInfoIndex =  state.get('bankingInfo')
                .findIndex(specificBankingInfo => specificBankingInfo.get('memberGuid') === action.payload.specificBankingInfo.memberGuid);

            return state.setIn(['bankingInfo', specificBankingInfoIndex], fromJS(action.payload.specificBankingInfo));
        }
        default:
            return state;
    }
};