import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../../../components/controls/FormGroup';
import { Field, reduxForm } from 'redux-form/immutable';
import InputText from '../../../components/controls/InputText';
import Button from '../../../components/controls/Button';
import ConfirmPassword from '../../../components/ConfirmPassword/ConfirmPassword';
import partnerManagerResources from './partnerManagerResources';
import { defaultFormProperties } from '../../../utilities/form';
import validate, * as partnerManagerValidation from './partnerManagerValidation';
import resources from '../../Shared/resources';

function ManagePartnerManagerForm({
    pristine,
    submitting,
    handleSubmit,
    isShowPasswordControls,
    onCancel
}) {
    return (
        <FormGroup onSubmit={handleSubmit}>
            <Field
                id="first_name"
                label={partnerManagerResources.labelFirstName}
                name="firstName"
                component={InputText}
                validate={[
                    partnerManagerValidation.firstNameRequired,
                    partnerManagerValidation.firstNameMaxLength
                ]}
            />
            <Field
                id="last_name"
                label={partnerManagerResources.labelLastName}
                name="lastName"
                component={InputText}
                validate={[
                    partnerManagerValidation.lastNameRequired,
                    partnerManagerValidation.lastNameMaxLength
                ]}
            />
            <Field
                id="email"
                label={partnerManagerResources.labelEmailAddress}
                name="email"
                component={InputText}
                validate={[
                    partnerManagerValidation.emailAddressRequired,
                    partnerManagerValidation.emailAddressFormat,
                    partnerManagerValidation.emailAddressMaxLength
                ]}
            />
            {isShowPasswordControls ? <ConfirmPassword /> : <></>}
            <div className="grid__row">
                <div className="col-xs-12 col-md-3 col-lg-4">
                    <Button
                        size="medium"
                        type="submit"
                        disabled={pristine || submitting}
                    >
                        {resources.saveButtonText}
                    </Button>
                </div>
                <div className="col-xs-12 col-md-3 col-lg-4">
                    {
                        onCancel && <Button
                            size="medium"
                            type="button"
                            onClick={onCancel}
                                    >
                            {resources.cancelButtonText}
                        </Button>
                    }
                </div>
            </div>
        </FormGroup>
    );
}

ManagePartnerManagerForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isShowPasswordControls: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
};

ManagePartnerManagerForm.defaultProps = {
    isShowPasswordControls: true
};

export default reduxForm({
    form: 'managePartnerManagerForm',
    validate,
    ...defaultFormProperties
})(ManagePartnerManagerForm);
