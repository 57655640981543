import React from 'react';
import './_slider.scss';

function CustomPaging() {
    return (
        <div>
            <button className="custom-dot" />
        </div>
    );
}

export default CustomPaging;