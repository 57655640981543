import * as types from './types';

const setLoading = () => ({
    type: types.SET_LOADING,
});

const unsetLoading = () => ({
    type: types.UNSET_LOADING,
});

const setIsBankingRequiredAction = isBankingRequired => ({
    type: types.SET_IS_BANKING_REQUIRED,
    payload: { isBankingRequired },
});

const setIsReimbursementEnabledAction = isReimbursementEnabled => ({
    type: types.SET_IS_REIMBURSEMENT_ENABLED,
    payload: { isReimbursementEnabled },
});

const doChecks = membersIds => (
    dispatch,
    getState,
    { AccountInfoService }
) => {
    dispatch(setLoading());
    const checkBankingRequired = AccountInfoService.checkBankingRequired(membersIds);
    const checkReimbursementEnabled = AccountInfoService.checkReimbursementEnabled(membersIds);
    Promise.all([checkBankingRequired, checkReimbursementEnabled]).then((data) => {
        const isBankingRequired = data[0];
        const isReimbursementEnabled = data[1];
        dispatch(setIsBankingRequiredAction(isBankingRequired));
        dispatch(setIsReimbursementEnabledAction(isReimbursementEnabled));
    })
        .finally((error) => {
            dispatch(unsetLoading());
        });
};

export default {
    doChecks,
};
