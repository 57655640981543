import React from 'react';
import PropTypes from 'prop-types';

function MetricsSectionItem({ header, description }) {
    return <div className="metrics-section__item">
        <div className="metrics-section__item-header">{header}</div>
        <div className="metrics-section__item-description">{description}</div>
    </div>;
}

MetricsSectionItem.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default MetricsSectionItem;