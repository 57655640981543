import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../../components/Spinner/Spinner';
import selectors from './legalContractSelectors';
import { getMemberLegalContractInfo } from './legalContractActions';
import LegalContractForm from './LegalContractForm/legalContractForm';
import legalContractResources from './legalContractResources';
import { format } from 'react-string-format';
import './_legalContract.scss';
import IconCaption from '../../../../components/IconCaption/IconCaption';
import MultiStepForms from '../../../../components/FormStep/MultiStepForms';
import KeyfobForm from './LegalContractSteppedFormForms/KeyfobForm';
import AgreementForm from './LegalContractSteppedFormForms/AgreementForm';
import BankingInfoStepForm from '../../../Shared/BankingInfo/BankingInfoStepForm';
import programPaymentTypes from '../../../../enums/programPaymentTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';

function LegalContract({
    isLoading,
    facilityDisplayName,
    facilityFullAddress,
    memberIdGuid,
    readOnly,
    membersAFFacilityWithinProgramEnrollment,
    getMemberLegalContractInfo,
    handleSubmit,
    programPaymentType,
    facilityAFNumber,
    documentAgreementVersion }) {
    useEffect(() => {
        getMemberLegalContractInfo(memberIdGuid);
    }, [memberIdGuid]);
    const { gmrAddProgram, gmAccessPassFeature } = useFlags();
    const forms = gmrAddProgram && gmAccessPassFeature ? [] : [{ title: legalContractResources.legalContractTitleKeyfobStep, formComponent: KeyfobForm }];
    if (gmrAddProgram && programPaymentType === programPaymentTypes.partnerMemberPaid) {
        forms.push({ title: legalContractResources.legalContractTitleBillingStep, formComponent: BankingInfoStepForm });
    };
    forms.push({ title: legalContractResources.legalContractTitleAgreementStep, formComponent: AgreementForm, formParams: { programPaymentType, facilityAFNumber } });
    return isLoading || (readOnly && !documentAgreementVersion)
        ? <Spinner />
        : <div className="legal-contract">
            <h3 className="legal-contract__title">{format(legalContractResources.legalCotractTitleFormat, handleSubmit ? legalContractResources.legalCotractTitleEnroll : legalContractResources.legalCotractTitleEnrolled, facilityDisplayName)}</h3>
            {
                facilityFullAddress ?
                    <div className="legal-contract__subtitle">
                        <IconCaption icon="place" className="legal-contract__box-icon" children={facilityFullAddress} />
                    </div>
                    : <></>
            }
            {
                readOnly ?
                    <LegalContractForm initialValues={membersAFFacilityWithinProgramEnrollment} onSubmit={handleSubmit} readOnly={readOnly} documentAgreementVersion={documentAgreementVersion} programPaymentType={programPaymentType}
                        facilityAFNumber={facilityAFNumber}
                    />
                    : <MultiStepForms forms={forms} onSubmit={handleSubmit} formName={legalContractResources.formNameLegalContract} />
            }
        </div>;
}

LegalContract.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    getMemberLegalContractInfo: PropTypes.func.isRequired,
    membersAFFacilityWithinProgramEnrollment: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func,
    memberIdGuid: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    facilityDisplayName: PropTypes.string,
    facilityFullAddress: PropTypes.string,
    programPaymentType: PropTypes.number,
    facilityAFNumber: PropTypes.string.isRequired,
    documentAgreementVersion: PropTypes.number
};

const mapDispatchToProps = {
    getMemberLegalContractInfo
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(LegalContract);