import { createSelector } from 'reselect';
import { GET_VISITS, DOWNLOAD_VISITS } from './visitLogTabTypes';
import { GET_CLOSED_USAGE_PERIODS } from '../../Shared/Periods/periodsTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === GET_VISITS || i === GET_CLOSED_USAGE_PERIODS || i === DOWNLOAD_VISITS
    ).size;
    return size > 0;
};

const getPeriods = state => state.periodsReducer.get('closedUsagePeriods');
const getVisits = state => state.visitLogTabReducer.get('visits');
const getDefaultPeriod = createSelector([getPeriods], (periods) => {
    return periods.first() || {};
});
const getSelectedPeriod = state => state.visitLogTabReducer.get('visitLogFilter').get('period');

export default createSelector(
    [getIsLoading, getPeriods, getSelectedPeriod, getVisits, getDefaultPeriod],
    (isLoading, periods, selectedPeriod, visits, defaultPeriod) => ({
        isLoading,
        periods,
        selectedPeriod,
        visits,
        defaultPeriod
    })
);