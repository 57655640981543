export default (values, props) => {
    const errors = {};

    if (values.getIn(['submissionPeriodFrom', 'id']) > values.getIn(['submissionPeriodTo', 'id'])) {
        const periodRangeErrorText = 'End month should be greater than start month.';

        errors.submissionPeriodFrom = periodRangeErrorText;
        errors.submissionPeriodTo = periodRangeErrorText;

        errors._error = periodRangeErrorText;
    }

    return errors;
};