import validationRules from '../../../../utilities/validationRules';
import groupMembershipsInfoResources from './groupMembershipsInfoResources';
import programPaymentTypes from '../../../../enums/programPaymentTypes';

export const programFeeRequired = validationRules.required(
    groupMembershipsInfoResources.labelProgramFee
);
export const programFeeRange  = validationRules.range(
    groupMembershipsInfoResources.labelProgramFee,
    0,
    99999999.99
);

export const programFeeGreaterThanNumberOfMembershipsAndDuesForOneMemberPerMonthPaidToFacilityRequiredMultiplication = validationRules.greaterThanMultiplicationIf(
    'numberOfMemberships',
    'duesForOneMemberPerMonthPaidToFacility',
    'Dues for 1 member per month paid to the facility multiplied by Number Of Memberships cannot be greater than Program Fee.',
    groupMembershipsInfoResources.fieldProgramPaymentType,
    programPaymentTypes.partnerPaid
);

export const numberOfMembershipsRequired  = validationRules.required(
    groupMembershipsInfoResources.labelNumberOfMemberships
);
export const numberOfMembershipsRange  = validationRules.range(
    groupMembershipsInfoResources.labelNumberOfMemberships,
    0,
    999999
);

export const duesForOneMemberPerMonthPaidToFacilityRequired = validationRules.required(
    groupMembershipsInfoResources.labelDuesForOneMemberPerMonthPaidToFacility
);
export const duesForOneMemberPerMonthPaidToFacilityRange = validationRules.range(
    groupMembershipsInfoResources.labelDuesForOneMemberPerMonthPaidToFacility,
    0,
    999.99
);

export const numberOfMembershipsValidation = function(values, keyfobsCount, resolve, reject) {
    if (keyfobsCount >= 0 && values.get('numberOfMemberships') < keyfobsCount) {
        reject({ numberOfMemberships: 'Number of memberships cannot be less than the number of keyfobs added to Partner. If you want to reduce Number of memberships then remove extra keyfobs.' });
    }
    else resolve();
};

export const adminFeeRequired = validationRules.required(
    groupMembershipsInfoResources.labelAdminFee
);
export const adminFeeRange  = validationRules.range(
    groupMembershipsInfoResources.labelAdminFee,
    0,
    999.99
);

export const programPaymentTypeRequired = validationRules.required(
    groupMembershipsInfoResources.labelProgramPaymentType
);

export const reimbursementTypeGreater = validationRules.greaterThanOrEqualSpecificValue(
    1,
    groupMembershipsInfoResources.labelReimbursementType
);

export const memberReimbursementTypeRequired = validationRules.requiredIf(
    groupMembershipsInfoResources.fieldProgramPaymentType,
    groupMembershipsInfoResources.labelReimbursementType,
    programPaymentTypes.memberPaid
);

export const reimbursementAmountRequired = validationRules.requiredIf(
    groupMembershipsInfoResources.fieldProgramPaymentType,
    groupMembershipsInfoResources.labelReimbursementAmount,
    programPaymentTypes.memberPaid
);
export const reimbursementAmountRange  = validationRules.range(
    groupMembershipsInfoResources.labelReimbursementAmount,
    0,
    999.99
);

export const numberOfVisitsToReimburseRequired = validationRules.requiredIf(
    groupMembershipsInfoResources.fieldProgramPaymentType,
    groupMembershipsInfoResources.labelNumberOfVisitsToReimburse,
    programPaymentTypes.memberPaid
);

export const numberOfVisitsToReimburseRange = validationRules.range(
    groupMembershipsInfoResources.labelNumberOfVisitsToReimburse,
    0,
    31
);

export const duesAmountRequired = validationRules.required(
    groupMembershipsInfoResources.labelDuesAmount
);
export const duesAmountRange = validationRules.range(
    groupMembershipsInfoResources.labelDuesAmount,
    0,
    999.99
);