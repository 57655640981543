import {
    GET_BANKING_INFO_SUCCESS,
    UPDATE_BANKING_INFO_SUCCESS,
    SET_LOADING,
    UNSET_LOADING
} from './types';

const setLoading = () => ({
    type: SET_LOADING
});

const unsetLoading = () => ({
    type: UNSET_LOADING
});

const getBankingInfoSuccess = bankingInfo => ({
    type: GET_BANKING_INFO_SUCCESS,
    payload: {
        bankingInfo
    }
});

const getBankingInfo = membersGuids => (
    dispatch,
    getState,
    { BankingInfoService }
) => {
    dispatch(setLoading());

    BankingInfoService.getBankingInfo(membersGuids)
        .then((bankingInfo) => {
            dispatch(getBankingInfoSuccess(bankingInfo));
            dispatch(unsetLoading());
        })
        .catch(() => {
            dispatch(unsetLoading());
        });
};

const updateBankingInfoSuccess = specificBankingInfo => ({
    type: UPDATE_BANKING_INFO_SUCCESS,
    payload: {
        specificBankingInfo
    }
});

const submit = bankingInfo => (dispatch, getState, { BankingInfoService }) => {
    dispatch(setLoading());

    BankingInfoService.updateBankingInfo(bankingInfo)
        .then((result) => {
            dispatch(updateBankingInfoSuccess(result.data));
            dispatch(unsetLoading());
        })
        .catch(() => {
            dispatch(unsetLoading());
        });
};

export default {
    getBankingInfo,
    submit
};
