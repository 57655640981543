import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setIsForgotPasswordPopupOpen } from './forgotPasswordActions';
import CustomPopup from '../Popups/CustomPopup/CustomPopup';
import ForgotPasswordForm from './ForgotPasswordForm';
import forgotPasswordResources from './forgotPasswordResources';
import selectors from './forgotPasswordSelectors';
import { useLocation } from 'react-router-dom';

function ForgotPasswordPopup({ isForgotPasswordPopupOpen, setIsForgotPasswordPopupOpen, isLoading }) {
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const showPopup = queryParams.get(forgotPasswordResources.paramName);
    useEffect(() => {
        if (showPopup) {
            setIsForgotPasswordPopupOpen(showPopup);
        }
    }, [showPopup]);
    return <CustomPopup
        isLoading={isLoading}
        showPopup={isForgotPasswordPopupOpen}
        onCancel={() => setIsForgotPasswordPopupOpen(false)}
        title={forgotPasswordResources.title}
        subtitle={forgotPasswordResources.subtitle}
        body={<ForgotPasswordForm closePopup={() => setIsForgotPasswordPopupOpen(false)} />}
           />;
}

ForgotPasswordPopup.propTypes = {
    isForgotPasswordPopupOpen: PropTypes.bool.isRequired,
    setIsForgotPasswordPopupOpen: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    captchaRef: PropTypes.object
};

const mapDispatchToProps = {
    setIsForgotPasswordPopupOpen
};

const connectStateToProps = connect(selectors, mapDispatchToProps);
export default connectStateToProps(ForgotPasswordPopup);