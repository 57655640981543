import { fromJS } from 'immutable';
import {
    SET_LOADING,
    UNSET_LOADING,
    GET_REIMBURSEMENT_HISTORY_SUCCESS,
    SET_PLAN_REIMBURSEMENTS_GROUP,
} from './types';

import { initialState } from './initials';

const getReimbursementsGroupIndex = (state, planId) => {
    return state.getIn(['reimbursementHistory', 'reimbursementsGroups'])
        .findIndex(reimbursementsGroup => reimbursementsGroup.get('planId') === planId);
};

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;

    switch (type) {
        case SET_LOADING:
            return state.set('isLoading', true);

        case UNSET_LOADING:
            return state.set('isLoading', false);

        case GET_REIMBURSEMENT_HISTORY_SUCCESS: {
            return state.set('reimbursementHistory', fromJS(payload.reimbursementHistory));
        }
        case SET_PLAN_REIMBURSEMENTS_GROUP: {
            const { planReimbursementsGroup } = payload;
            const index = getReimbursementsGroupIndex(state, planReimbursementsGroup.planId);
            return state.mergeIn(['reimbursementHistory', 'reimbursementsGroups', index], fromJS(planReimbursementsGroup));
        }
        default:
            return state;
    }
}