import React from 'react';
import PropTypes from 'prop-types';
import './_login.scss';
import loginResources from './loginResources';
import Button from '../../components/controls/Button';
import { API_PATHS } from '../../http/configs';
import LoginDataForm from './LoginDataForm';
import { connect } from 'react-redux';
import { login } from './loginActions';
import selectors from './loginSelectors';

export function LoginBody({ closePopup, login, redirectUrl }) {
    const facilityEnrollmentHandler = () => {
        closePopup();
        window.location = API_PATHS.facilityEnrollment;
    };
    const memberEnrollmentHandler = () => {
        closePopup();
        window.location = API_PATHS.memberEnrollment;
    };

    return <div className="login">
        <div className="login__main-section">
            <LoginDataForm onSubmit={(loginData) => {login(loginData, redirectUrl);}} />
        </div>
        <hr className="login__divider" />
        <div className="login__signup-section">
            <div className="login__signup-section-text">
                {loginResources.signupText}
            </div>
            <div className="login__signup-buttons-wrapper">
                <Button className="btn_new btn_new--white login__signup-button"
                    onClick={facilityEnrollmentHandler}
                >
                    {loginResources.facilitySignupButtonText}
                </Button>
                <Button className="btn_new btn_new--white login__signup-button"
                    onClick={memberEnrollmentHandler}
                >
                    {loginResources.memberSignupButtonText}
                </Button>
            </div>
        </div>
    </div>;
};

LoginBody.propTypes = {
    closePopup: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string
};

export default connect(selectors, { login })(LoginBody);
