import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiInputText } from '../controls/MuiInputText';
import './_new-editable-cell.scss';
import { fromJS } from 'immutable';
import MaterialIcon from '../../components/MaterialIcon/MaterialIcon';
import ToolTip from '../../components/controls/ToolTip';
import sharedResources from '../../features/Shared/resources';

function NewEditableCell({
    value: initialValue,
    row,
    column
}) {
    useEffect(() => {
        setValue(initialValue);
        setOldValue(initialValue);
        setIsEditMode(false);
    }, [initialValue]);
    const [value, setValue] = useState();
    const [isEditMode, setIsEditMode] = useState(false);
    const [oldValue, setOldValue] = useState();
    const [validationError, setValidationError] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [touched, setTouched] = useState(false);
    const props = column.getProps(row);
    const onBlur = () => {
        updateCellHandler();
    };
    const onFocus = () => {
        setTouched(true);
        setShowErrorMessage(false);
    };
    const onChange = (e) => {
        setValue(e.target.value);
    };
    const undoChangeHandler = () => {
        setValue(oldValue);
        setValidationError('');
        setShowErrorMessage(false);
        setIsEditMode(!isEditMode);
        setOldValue(oldValue);
    };
    const updateCellHandler = () => {
        for (const validate of props.validateCellRules) {
            const errorMessage = validate(value, fromJS(row.original));
            if (errorMessage) {
                setValidationError(errorMessage);
                setShowErrorMessage(true);
                return;
            }
        }
        props.updateCellHandler(value, oldValue);
        setIsEditMode(!isEditMode);
    };
    const component =
        <div className="new-editable-cell">
            {!isEditMode
                ?
                <>
                    <div className="new-editable-cell__plain-value">{value}</div>
                    {props.isEditButtonEnabled &&
                        <ToolTip title={props.editTooltip} arrow
                            placement="top"
                        >
                            <div>
                                <button className="new-editable-cell__button new-editable-cell__button--blue" onClick={() => setIsEditMode(!isEditMode)}
                                    disabled={props.disabled}
                                >
                                    <MaterialIcon className="new-editable-cell__button-icon" icon="edit"
                                        variant={'filled'}
                                    />
                                </button>
                            </div>
                        </ToolTip>}
                </>
                :
                <>
                    <ToolTip title={validationError} arrow
                        placement="top"
                        isOpen={showErrorMessage}
                        id="editableCellError"
                    >
                        <div>
                            <MuiInputText
                                input={{ value: value || '', onBlur, onChange, onFocus }}
                                meta={{ error: validationError, touched }}
                                showErrorMessage={false}
                            />
                        </div>
                    </ToolTip>
                    <button className="new-editable-cell__button new-editable-cell__button--green" onClick={updateCellHandler}>
                        <MaterialIcon className="new-editable-cell__button-icon" icon="check_circle"
                            variant={'filled'}
                        />
                    </button>
                    <button className="new-editable-cell__button new-editable-cell__button--red" onClick={undoChangeHandler}>
                        <MaterialIcon className="new-editable-cell__button-icon" icon="cancel"
                            variant={'filled'}
                        />
                    </button>
                </>}
        </div>;
    return !props.isVisible
        ? <></>
        : component;
};

NewEditableCell.defaultProps = {
    updateCellHandler: () => { },
    editTooltip: sharedResources.editLabel
};

NewEditableCell.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    updateCellHandler: PropTypes.func.isRequired,
    validateCellRules: PropTypes.arrayOf(PropTypes.func),
    disabled: PropTypes.bool,
    isEditButtonEnabled: PropTypes.bool,
    editTooltip: PropTypes.string,
    isVisible: PropTypes.bool,
};

export default NewEditableCell;