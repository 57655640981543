import React, { useEffect } from 'react';
import SectionsDivider from '../../../../components/SectionsDivider/SectionsDivider';
import CallToAction from '../../../../components/CallToAction/CallToAction';
import CallToActionBody from '../../../../components/CallToAction/CallToActionBody';
import CallToActionImage from '../../../../components/CallToAction/CallToActionImage';
import guestAreaSharedResources from '../guestAreaSharedResources';
import PropTypes from 'prop-types';
import ClubLocatorSection from '../../../../components/ClubLocatorSection/ClubLocatorSection';
import { API_PATHS } from '../../../../http/configs';
import ContactUsSection from '../ContactUs/ContactUsSection';
import MetaData from '../../../../components/MetaData/MetaData';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';
import { HC_PHONE_NUMBER } from '../../../../constants/phones';
import '../../../../styles/_public-page.scss';
import SolutionsSectionItem from '../../../../components/SolutionsSection/SolutionsSectionItem';
import SolutionsSection from '../../../../components/SolutionsSection/SolutionsSection';
import TextSection from '../../../../components/TextSection/TextSection';
import { isReactSnap } from '../../../../utilities/userAgent';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';

function ForMembers({ history }) {
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle('For Members');
    }, []);
    function joinUsHandler() {
        window.location = API_PATHS.memberEnrollment;
    };
    return <div className="public-page">
        <MetaData keywords="for members"
            title={`For Members, ${HEALTHY_CONTRIBUTIONS_NAME}, Call: ${HC_PHONE_NUMBER}`}
            description={`${HEALTHY_CONTRIBUTIONS_NAME} makes it easier to live a healthier and happier lifestyle with employee fitness & wellness benefits`}
        />
        <CallToAction>
            <CallToActionBody subtitle={guestAreaSharedResources.callToActionSubtitle}
                title={<>Start your wellness journey</>}
                description="Everyone's wellness journey is different. For members, Healthy Contributions helps make it easy to maximize your fitness benefit and live a healthier, happier lifestyle."
            />
            <CallToActionImage image="fitnessGroup2"
                imageAlt={guestAreaSharedResources.signUpTopImageAlt}
                imageClassName="fitness-group2"
            />
        </CallToAction>
        <SectionsDivider />
        <CallToAction theme="without-image">
            <CallToActionBody
                title={<>Anytime Fitness <span className="green">Group Memberships</span></>}
                description={<>When your employer partners with Healthy Contributions on your Anytime Fitness Group Membership, you get a tailored fitness program, personalized support, anytime access, the AF app, and an entire community to help motivate you on your wellness journey.<br /><br />Sign up for your Group Membership today by creating your member profile and activating your key fob below.</>}
                buttonText={guestAreaSharedResources.activateKeyFobButtonText} buttonClickHandler={joinUsHandler}
                columns={2}
            />
        </CallToAction>
        <SectionsDivider />
        <TextSection title={<>Fitness <span className="green">Reimbursement Programs</span></>}
            text="For members, when you have an eligible insurance- or employer-sponsored wellness plan, you may be eligible to receive a monthly reimbursement for visiting your gym once you meet your monthly visit requirement at a participating fitness facility."
            theme="column"
        />
        <SectionsDivider />
        <SolutionsSection header={<>Get paid to work out - <span className="green">we make it easy to participate!</span></>}>
            <SolutionsSectionItem materialIconName="add_location_alt" header="Step 1"
                description="Locate a participating health club on the club locator below"
            />
            <SolutionsSectionItem materialIconName="done_all" header="Step 2"
                description="Sign up at a participating club! When you visit the club, make sure to present proper documentation on your fitness benefit"
            />
            <SolutionsSectionItem materialIconName="fitness_center" header="Step 3"
                description="Work out at your club like normal. The club tracks your visits and submits them for you in the Healthy Contributions portal"
            />
            <SolutionsSectionItem materialIconName="loyalty" header="Step 4"
                description="Meet your monthly visit requirement and receive $ towards your monthly dues as determined by your program"
            />
        </SolutionsSection>
        <SectionsDivider />
        <>
            {!isReactSnap() && <ClubLocatorSection header={<>Find <span className="green">your club</span></>} history={history} />}
        </>
        <SectionsDivider />
        <ContactUsSection history={history}>
            <CallToActionImage image={'fitnessPark'}
                imageAlt={guestAreaSharedResources.contactUsBottomImageAlt}
                imageClassName={'fitness-park'}
            />
        </ContactUsSection>
        <SectionsDivider />
    </div>;
}

ForMembers.propTypes = {
    history: PropTypes.object.isRequired
};

export default ForMembers;