import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/controls/Button';

function ActionCell({ value, column, row }) {
    const props = column.getProps(row);
    return (
        <Button
            theme={props.theme}
            size="small"
            onClick={() => props.handler(value, row)}
        >
            {props.label}
        </Button>
    );
}

ActionCell.propTypes = {
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    handler: PropTypes.func,
    theme: PropTypes.string,
};

export default ActionCell;
