import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import selectors from './headerSelectors';
import actions from '../../features/App/actions';
import { API_PATHS } from '../../http/configs';
import { authUserType } from '../../propTypes/customTypes';
import routes from '../../routing/routes';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import './header.scss';
import Image from '../Image/Image';
import { setIsLoginPopupOpen } from '../Login/loginActions';
import HeaderNavList from './HeaderNavList';
import { isMobileSize } from '../../utilities/windowSize';

function Header({ selectedMemberGroup, me, changeSelectedMemberGroup, setIsLoginPopupOpen }) {
    const [isNavigationMenuExpanded, setIsNavigationMenuExpanded] = useState(false);
    const [isAccountMenuExpanded, setIsAccountMenuExpanded] = useState(false);
    const isMobile = isMobileSize();
    const abbreviatedName = selectedMemberGroup.abbreviatedName ? selectedMemberGroup.abbreviatedName.replace(' ', '') : '';
    useEffect(() => {
        if (!isMobile) {
            setIsNavigationMenuExpanded(false);
        }
    }, [isMobile]);
    const headerProfileLinkRef = useRef();
    const handleWindowClick = function (e) {
        if (headerProfileLinkRef.current && !headerProfileLinkRef.current.contains(e.target)) {
            setIsAccountMenuExpanded(false);
        }
    };
    useEffect(() => {
        window.addEventListener('click', handleWindowClick);
        return () => { window.removeEventListener('click', handleWindowClick); };
    }, []);
    return (
        <div className="header">
            <div className="header__content d-flex justify-content-between">
                <button className="new-link header__open-menu-button" onClick={() => setIsNavigationMenuExpanded(true)}>
                    <MaterialIcon className="new-link__icon" icon="menu" />
                </button>
                <NavLink to={routes.home.path} className="header__logo">
                    <span className="header__logo-wrapper">
                        <Image image="logoGreen" imageHover="logoGreenHover"
                            alt="Healthy Contributions Logo"
                        />
                    </span>
                </NavLink>
                {
                    !isMobile || (isMobile && isNavigationMenuExpanded)
                        ? <nav className="header__nav">
                            <button className="new-link header__close-menu-link" onClick={() => setIsNavigationMenuExpanded(false)}>
                                <MaterialIcon className="new-link__icon" icon="close" />
                            </button>
                            <div className="header__nav-list-container">
                                <HeaderNavList isMobile={isMobile}/>
                            </div>
                        </nav>
                        : <></>
                }
                <div className="header__actions">
                    {me && me.role ?
                        <>
                            <div className="header__profile-link" ref={headerProfileLinkRef}>
                                <div tabIndex={0} className="header__profile-icon"
                                    onClick={() => setIsAccountMenuExpanded(!isAccountMenuExpanded)}
                                >{abbreviatedName}</div>
                                {
                                    isAccountMenuExpanded
                                        ?
                                            <div className="header__account-menu header__account-menu--visible">
                                                <div className="header__close-account-menu">
                                                    <button className="new-link header__close-account-menu-link" onClick={() => setIsAccountMenuExpanded(false)}>
                                                        <MaterialIcon className="new-link__icon" icon="close" />
                                                    </button>
                                                </div>
                                                <div className="header__account-fullname">
                                                    <div className="header__profile-icon">{abbreviatedName}</div>
                                                    {selectedMemberGroup.fullName}
                                                </div>
                                                {
                                                    me.memberGroups && me.memberGroups.length > 1
                                                        ?
                                                            <div className="header__accounts-list">
                                                                {
                                                                    me.memberGroups.map((group, i) =>
                                                                        <a href={`#member-group${i}`} className="new-link header__accounts-list-item"
                                                                            onClick={() => { setIsAccountMenuExpanded(false); changeSelectedMemberGroup(group); }} key={i}
                                                                        >
                                                                            {group.fullName}
                                                                        </a>
                                                                    )
                                                                }
                                                            </div>
                                                        :
                                                            <></>
                                                }
                                                {isMobile && <HeaderNavList isMobile={isMobile}/>}
                                                <div className="header__account-logout">
                                                    <a className="new-link" href={API_PATHS.logout}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setIsAccountMenuExpanded(false);
                                                            window.location.href = API_PATHS.logout;
                                                        }}
                                                    >
                                                        <MaterialIcon className="new-link__icon" icon="logout" />
                                                        <span className="header__link-label">logout</span>
                                                    </a>
                                                </div>
                                            </div>
                                        :
                                            <></>
                                }
                            </div>
                        </>
                        :
                        <button className="new-link" onClick={() => setIsLoginPopupOpen(true)}>
                            <MaterialIcon className="new-link__icon header__link-icon header__link-icon--login" icon="login" />
                            <span className="header__link-label header__link-label--login">login</span>
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

Header.propTypes = {
    selectedMemberGroup: PropTypes.shape({
        fullName: PropTypes.string,
    }),
    me: authUserType,
    changeSelectedMemberGroup: PropTypes.func,
    setIsLoginPopupOpen: PropTypes.func
};
const mapDispatchToProps = {
    setIsLoginPopupOpen,
    ...actions
};
export default connect(selectors, mapDispatchToProps)(Header);
