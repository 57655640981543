import * as types from './visitLogTabTypes';
import * as loadingActions from '../../Loading/loadingActions';
import visitLogTabService from './visitLogTabService';

const setPeriodAction = (period) => {
    return { type: types.SET_PERIOD, period };
};

function setPeriod(period) {
    return function (dispatch) {
        dispatch(setPeriodAction(period));
    };
}

const setVisits = (visits) => {
    return { type: types.SET_VISITS, visits };
};

function getVisits(partnerID, periodID) {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.GET_VISITS));
        return visitLogTabService
            .getVisits(partnerID, periodID)
            .then((visits) => {
                dispatch(setVisits(visits));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_VISITS));
            });
    };
}


function downloadVisits(partnerId, periodId) {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.DOWNLOAD_VISITS));
        return visitLogTabService
            .downloadVisits(partnerId, periodId)
            .finally(() => dispatch(loadingActions.unsetLoading(types.DOWNLOAD_VISITS)));
    };
}

export default {
    setPeriod,
    getVisits,
    downloadVisits
};