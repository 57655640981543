import bankAccountTypes from '../../../enums/bankAccountTypes';
const PartnerBillingInfoResources = {
    labelBillingType: 'Billing Type',
    titleWithdrawalFromBankingInfo: 'Withdrawal from banking info',
    formPartnerBillingInfo: 'partnerBillingInfoForm',
    fieldPartnerBillingTypeID: 'partnerBillingTypeID',
    labelRoutingNumber: 'Routing Number',
    fieldRoutingNumber: 'routingNumber',
    labelAccountNumber: 'Account Number',
    fieldAccountNumber: 'accountNumber',
    labelAccountType: 'Account Type',
    labelChecking: 'Checking',
    labelSavings: 'Savings',
    labelPayeeToken: 'Payee Token',
    fieldPayeeToken: 'payeeToken',
    fieldBankAccountType: 'bankAccountType',
    buttonRetrieve: 'Retrieve',
    warningBoxTitle: 'Please, know',
    warningBoxDescription: 'Your privacy, and compliance to HIPAA & PCI requirements are of the utmost importance. It is with industry leading technology safeguards we protect your information from the moment its types in and on through our Web and processing systems. We feel these extensive privacy features gives everyone peace of mind.'
};
export const PartnerBankAccountTypeOptions = Object.freeze([
    {
        id: 'account_type_savings',
        label: PartnerBillingInfoResources.labelSavings,
        value: bankAccountTypes.Savings.toString()
    },
    {
        id: 'account_type_checking',
        label: PartnerBillingInfoResources.labelChecking,
        value: bankAccountTypes.Checking.toString()
    }
]);
export default PartnerBillingInfoResources;
