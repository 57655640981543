import React from 'react';
import PropTypes from 'prop-types';
import './_forgot-password.scss';
import forgotPopupResources from './forgotPasswordResources';
import Button from '../controls/Button';
import { connect } from 'react-redux';
import { setIsLoginPopupOpen } from '../Login/loginActions';
import { forgotPassword, resend } from './forgotPasswordActions';
import selectors from './forgotPasswordSelectors';

export function ForgotPasswordResultBody({ closePopup, setIsLoginPopupOpen, username, resend }) {
    const openLoginPopupHandler = () => {
        closePopup();
        setIsLoginPopupOpen(true);
    };
    return <div className="forgot-popup__result">
        <div className="forgot-popup__result-buttons-wrapper">
            <Button className="btn_new btn_new--white"
                onClick={() => resend(username)}
            >
                {forgotPopupResources.resendButtonText}
            </Button>
            <Button className="btn_new btn_new--green"
                onClick={openLoginPopupHandler}
            >
                {forgotPopupResources.loginButtonText}
            </Button>
        </div>
    </div>;
};

ForgotPasswordResultBody.propTypes = {
    closePopup: PropTypes.func.isRequired,
    setIsLoginPopupOpen: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    username: PropTypes.string,
    captchaToken: PropTypes.string,
    resend: PropTypes.func.isRequired,
};

export default connect(selectors, { forgotPassword, setIsLoginPopupOpen, resend })(ForgotPasswordResultBody);
