import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import InfoBoxTable from '../../../../components/InfoBox/InfoBoxTable';
import CardInfo from '../../../../components/CardInfo/CardInfo';
import Spinner from '../../../../components/Spinner/Spinner';
import PlanReimbursementsGroupReloadForm from './PlanReimbursementsGroupReloadForm';

class ReimbursementsGroup extends Component {
    render() {
        const { handleSubmit, availableSubmissionPeriods, reimbursementsGroup, index } = this.props;

        return (
            <div className="reimbursement-history" id="reimbursement_history">
                <CardInfo title={reimbursementsGroup.get('name')}>
                    {reimbursementsGroup.get('isLoading') && <div><Spinner/></div>}
                    <div className="col-md-12">
                        <InfoBox>
                            <hr/>
                            {reimbursementsGroup.get('hideReimbursementHistory')
                                ? <div className="text-center">There are no reimbursement details.</div>
                                : <div>
                                    <PlanReimbursementsGroupReloadForm
                                        form={`planReimbursementsGroup_${index}`}
                                        key={index}
                                        availableSubmissionPeriods={availableSubmissionPeriods}
                                        initialValues={reimbursementsGroup}
                                        onSubmit={handleSubmit}
                                    />
                                    <hr/>
                                    {
                                        reimbursementsGroup.get('returns').count()
                                            ? (
                                                <InfoBoxTable
                                                    header={['month', 'visits', 'amount', 'status', 'reason']}
                                                    data={reimbursementsGroup.get('returns').toJS()}
                                                />
                                            )
                                            : <div className="text-center">No reimbursements for selected months.</div>
                                    }
                                </div>
                            }
                        </InfoBox>
                    </div>
                </CardInfo>
            </div>
        );
    }
}

ReimbursementsGroup.defaultProps = {
    handleSubmit: () => {
    }
};

ReimbursementsGroup.propTypes = {
    index: PropTypes.number,
    handleSubmit: PropTypes.func,
    reimbursementsGroup: PropTypes.instanceOf(Map),
    availableSubmissionPeriods: PropTypes.instanceOf(List)
};

export default ReimbursementsGroup;
