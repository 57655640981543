import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import Spinner from '../../../components/Spinner/Spinner';
import ReimbursementsGroup from './ReimbursementsGroup/view';
import withMemberGroup from '../../../hoc/withMemberGroup';
import './reimbursement-history.scss';

class ReimbursementHistory extends Component {
    render() {
        return (
            <div className="reimbursement-history" id="reimbursement_history">
                {
                    this.props.isLoading
                        ? <Spinner/>
                        : (
                            <div>
                                {
                                    this.props.reimbursementsGroups.map((reimbursementsGroup, index) => (
                                        <ReimbursementsGroup
                                            key={index}
                                            index={index}
                                            reimbursementsGroup={reimbursementsGroup}
                                            availableSubmissionPeriods={this.props.availableSubmissionPeriods}
                                            handleSubmit={this.props.reloadPlanReimbursementsGroup}
                                        />
                                    ))
                                }
                            </div>
                        )}
            </div>
        );
    }
}

ReimbursementHistory.propTypes = {
    getReimbursementHistory: PropTypes.func,
    reloadPlanReimbursementsGroup: PropTypes.func,
    availableSubmissionPeriods: PropTypes.instanceOf(List),
    selectedMemberGroup: PropTypes.object,
    reimbursementsGroups: PropTypes.instanceOf(List),
    isLoading: PropTypes.bool
};


const withMemberGroupObserved = withMemberGroup({
    actionName: 'getReimbursementHistory'
})(ReimbursementHistory);

export default connect(selectors, actions)(withMemberGroupObserved);
