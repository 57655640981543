
import React from 'react';
import PropTypes from 'prop-types';
import styles from './_currency-cell.module.scss';

function CurrencyCell({ value }) {
    return <div className={styles.currency_cell}>{value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    })}</div>;
}

CurrencyCell.propTypes = {
    value: PropTypes.number,
};

export default CurrencyCell;
