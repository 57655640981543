import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_info-box.scss';

export const InfoBoxRow = (props) => {
    const { className, label, content, children } = props;
    return (
        <div
            className={classNames(['info-box__row', { [className]: className }])}
            {...props}
        >
            {label && (
                <div className="info-box__cell info-box__label" title={label}>
                    {label}
                </div>
            )}
            <div
                className="info-box__cell"
                title={typeof content === 'string' ? content : ''}
            >
                {content == null ? children : content}
            </div>
        </div>
    );
};

InfoBoxRow.propTypes = {
    children: PropTypes.node,
    content: PropTypes.node,
    label: PropTypes.string,
    className: PropTypes.string,
};

export default InfoBoxRow;
