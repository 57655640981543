import React from 'react';
import PropTypes from 'prop-types';
import './_metrics-section.scss';
import TextSection from '../TextSection/TextSection';

function MetricsSection({ header, description, children, columns }) {
    return <div className="metrics-section">
        <TextSection title={header} text={description}
            theme="centered"
        />
        <div className={`metrics-section__items metrics-section__items--columns-${columns}`}>
            {children}
        </div>
    </div>;
}

MetricsSection.defaultProps = {
    columns: 3
};

MetricsSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    columns: PropTypes.number
};

export default MetricsSection;