import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import InputSelect from '../../../components/controls/InputSelect';
import Button from '../../../components/controls/Button';
import { defaultFormProperties } from '../../../utilities/form';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import * as validationRules from './paymentViaACHFormValidation';
import './_payment-via-ach-from.scss';

const PaymentViaACHForm = ({
    gmrAddProgram,
    pristine,
    submitting,
    handleSubmit,
    onSubmitSend,
    onSubmitDownload,
    programTypes,
    programPaymentTypes,
    partners,
    periods,
    isSendDisabled,
    sendErrorMessage,
    paymentTypes,
    paymentTypeFilterVisible,
    programPaymentTypeFilterDisabled }) => {
    return <FormGroup>
        {
            gmrAddProgram ?
                <>
                    <Field
                        label={wellnessProgramPaymentResources.labelProgramType}
                        name="programPaymentType"
                        options={programPaymentTypes}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                        component={InputSelect}
                        isDisabled={programPaymentTypeFilterDisabled}
                    />
                    {paymentTypeFilterVisible ?
                        <Field
                            label={wellnessProgramPaymentResources.labelPaymentType}
                            name="paymentType"
                            options={paymentTypes}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('id')}
                            component={InputSelect}
                            isDisabled
                        />
                        : <></>
                    }
                </>
                :
                <Field
                    label={wellnessProgramPaymentResources.labelForProgramType}
                    name="programType"
                    options={programTypes}
                    getOptionLabel={option => option.get('name')}
                    getOptionValue={option => option.get('id')}
                    component={InputSelect}
                    isDisabled
                />
        }
        <Field
            component={InputSelect}
            label={gmrAddProgram ? wellnessProgramPaymentResources.labelPartner : wellnessProgramPaymentResources.labelForPartner}
            name="partner"
            options={partners}
            getOptionLabel={option => option.get('name')}
            getOptionValue={option => option.get('id')}
            validate={gmrAddProgram ? [validationRules.partnerRequired] : [validationRules.forPartnerRequired]}
        />
        <Field
            component={InputSelect}
            label={gmrAddProgram ? wellnessProgramPaymentResources.labelVisitMonth : wellnessProgramPaymentResources.labelForUsagePeriod}
            name="period"
            options={periods}
            getOptionLabel={option => option.get('yearMonth')}
            getOptionValue={option => option.get('id')}
            validate={gmrAddProgram ? [validationRules.visitMonthRequired] : [validationRules.periodRequired]}
        />
        <div className="grid__row">
            <div className="col-lg-4">
                <Button
                    name="buttonSend"
                    disabled={pristine || submitting || isSendDisabled}
                    onClick={handleSubmit(values => onSubmitSend(values))}
                >
                    {wellnessProgramPaymentResources.buttonSendToUSBank}
                </Button>
            </div>
            <div className="col-lg-4">
                <Button
                    name="buttonDownload"
                    disabled={pristine || submitting}
                    onClick={handleSubmit(values => onSubmitDownload(values))}
                >
                    {wellnessProgramPaymentResources.buttonDownloadForPreview}
                </Button>
            </div>
            {sendErrorMessage && (
                <div className="payment-via-ach-form__submit-validation-error">{sendErrorMessage}</div>
            )}
        </div>
    </FormGroup>;
};

PaymentViaACHForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    gmrAddProgram: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onSubmitSend: PropTypes.func.isRequired,
    onSubmitDownload: PropTypes.func.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    periods: PropTypes.object,
    isSendDisabled: PropTypes.bool.isRequired,
    sendErrorMessage: PropTypes.string,
    paymentTypes: PropTypes.object,
    paymentTypeFilterVisible: PropTypes.bool,
    programPaymentTypeFilterDisabled: PropTypes.bool
};

export default reduxForm({
    ...defaultFormProperties
})(PaymentViaACHForm);
