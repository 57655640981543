import React, { useEffect } from 'react';
import MetricsSection from '../../../../components/MetricsSection/MetricsSection';
import MetricsSectionItem from '../../../../components/MetricsSection/MetricsSectionItem';
import SectionsDivider from '../../../../components/SectionsDivider/SectionsDivider';
import SolutionsSectionItem from '../../../../components/SolutionsSection/SolutionsSectionItem';
import SolutionsSection from '../../../../components/SolutionsSection/SolutionsSection';
import PartnersSection from '../../../../components/PartnerSection/PartnersSection';
import PartnersSectionItem from '../../../../components/PartnerSection/PartnersSectionItems';
import TestimonialSection from '../../../../components/TestimonialSection/TestimonialSection';
import TestimonialSectionItem from '../../../../components/TestimonialSection/TestimonialSectionItem';
import optum from '../../../../assets/images/optum.svg';
import fitonhealth from '../../../../assets/images/FitOn-Health-logo.png';
import CallToAction from '../../../../components/CallToAction/CallToAction';
import CallToActionBody from '../../../../components/CallToAction/CallToActionBody';
import CallToActionImage from '../../../../components/CallToAction/CallToActionImage';
import guestAreaSharedResources from '../guestAreaSharedResources';
import { API_PATHS } from '../../../../http/configs';
import ash from '../../../../assets/images/ash.svg';
import PropTypes from 'prop-types';
import ContactUsSection from '../ContactUs/ContactUsSection';
import { MEDICAL_PARTNER_IMAGES, OPTUM_ALT_TAG, FITON_HEALTH_ALT_TAG, ASH_ALT_TAG } from '../../../../constants/partnerImageGroups';
import MetaData from '../../../../components/MetaData/MetaData';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';
import { HC_PHONE_NUMBER } from '../../../../constants/phones';
import '../../../../styles/_public-page.scss';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';

function ForInsurers({ history }) {
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle('For Insurers');
    }, []);
    function joinUsHandler() {
        window.location = API_PATHS.calendlyUrl;
    };
    return <div className="public-page">
        <MetaData keywords="for insurers"
            title={`For Insurers, ${HEALTHY_CONTRIBUTIONS_NAME}, Call: ${HC_PHONE_NUMBER}`}
            description={`${HEALTHY_CONTRIBUTIONS_NAME} gives your members the benefit of wellness with insurance-compliant health incentives`}
        />
        <CallToAction>
            <CallToActionBody subtitle={guestAreaSharedResources.callToActionSubtitle}
                title={<>Improving health, one wellness program at a time</>}
                description={<>{guestAreaSharedResources.requestDemoDescriptionText}<br /><br />Be seen as a population health and wellness leader.</>}
                buttonText={guestAreaSharedResources.requestDemoButtonText}
                buttonClickHandler={joinUsHandler}
            />
            <CallToActionImage image="scrubsWomen"
                imageAlt={guestAreaSharedResources.requestDemoTopImageAlt}
                imageClassName="scrubs-women"
            />
        </CallToAction>
        <SectionsDivider />
        <MetricsSection header={<>Give your members the benefit of wellness with <span className="green">insurance-compliant health incentives</span></>}
            description="Healthy Contributions’ solutions are trusted by health plans across the US to provide their customers with secure data processing and wellness solutions."
        >
            <MetricsSectionItem
                header="17k+ Fitness Network Partners"
                description="For insurers, attract more business by leveraging our growing network of wellness partners to help your clients and employer groups build healthier, happier, and more productive workplaces."
            />
            <MetricsSectionItem
                header="15+ Years of experience securely managing programs for insurance partners"
                description="Count on expertise and steadfast commitment you can trust. Work with our subject matter experts and dedicated account managers to help you successfully grow and sustain your wellness program."
            />
            <MetricsSectionItem
                header="$1.3M+ In processed, secure funds reimbursements"
                description="Our robust proprietary platform helps you reward your members securely and scale your program for future growth all while remaining HIPAA and PII compliant."
            />
        </MetricsSection>
        <SectionsDivider />
        <SolutionsSection header={<>Health and wellness solutions for <span className="green">Insurers</span></>}
            description="Expand your reach by leveraging Healthy Contributions’ strategic partnerships, proprietary processing digital platform, and growing fitness network to deliver health solutions designed with your members’ health in mind. "
            headerTheme="column-on-large-screen"
        >
            <SolutionsSectionItem materialIconName="favorite_border" header="Medicare and Medicaid Programs"
                description="Gym memberships or fitness programs may be part of the extra coverage offered by Medicare Advantage Plans, other Medicare health plans, or Medicare Supplement Insurance (Medigap) plans."
            />
            <SolutionsSectionItem materialIconName="settings_backup_restore" header="Reimbursement Programs"
                description="Member enrolls and pays regular dues, then the member receives a reimbursement from the payor for achieving a determined  number of visits per month"
            />
        </SolutionsSection>
        <SectionsDivider />
        <TestimonialSection header={<>Testimonials</>}>
            <TestimonialSectionItem
                description="Optum has worked with Healthy Contributions since 2010 to serve as a payment processor for our network locations. The Healthy Contributions team that supports us has been flexible and reliable as our products have evolved and grown. They have enhanced their platform based on our business needs and from direct gym and fitness location feedback. I have had the pleasure of working closely with many of the support staff and they have been true partners that are responsive, kind, and dependable."
                logoName={optum}
                logoAlt={OPTUM_ALT_TAG}
                name="Vince Pozinski"
                position="Sr. Product Director at Optum"
            />
            <TestimonialSectionItem name="Kate Bosse"
                position="Director, Network Management"
                description="They truly take a partnership approach and deliver high quality service which in turn allows our members to have the best experience possible."
                logoName={fitonhealth}
                logoAlt={FITON_HEALTH_ALT_TAG}
            />
            <TestimonialSectionItem name="Jeff Na"
                position="Vice President of Fitness"
                logoName={ash}
                logoAlt={ASH_ALT_TAG}
                description="We have thoroughly enjoyed working with Health Contributions as they continue to be supportive and communicative with our Anytime Fitness partnership."
            />
        </TestimonialSection>
        <SectionsDivider />
        <PartnersSection header={<>Our <span className="green">Partners</span></>}>
            {MEDICAL_PARTNER_IMAGES.map((imageInfo, index) =>
                <PartnersSectionItem key={index}
                    alt={imageInfo.alt}
                    imageObject={imageInfo.imageName}
                    link={imageInfo.link}
                />
            )
            }
        </PartnersSection>
        <SectionsDivider />
        <ContactUsSection
            history={history}
            title={'Prioritize your members’ health by offering a tailored wellness benefit'}
        >
            <CallToActionImage image={'manWprostheticWorkout'}
                imageAlt={guestAreaSharedResources.contactUsBottomImageAlt}
                imageClassName={'man-wprosthetic-workout'}
            />
        </ContactUsSection>
        <SectionsDivider />
    </div>;
}

ForInsurers.propTypes = {
    history: PropTypes.object.isRequired
};

export default ForInsurers;