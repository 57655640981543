import React from 'react';
import MaterialIcon from '../../MaterialIcon/MaterialIcon';
import PropTypes from 'prop-types';

function SelectedPlan({ plan, onRemove }) {
    const removePlan = () => {
        onRemove(plan);
    };

    return (
        <span className={'icon-caption'}>
            <span className={'icon-caption__icon'} onClick={removePlan}>
                <MaterialIcon className="filter-box__close-icon" icon="cancel" />
            </span>
            <span className="icon-caption__text">{
                plan.programNames && plan.programNames.length > 0
                    && !plan.programNames.filter(pn => pn.name && pn.name.length > 0).every(pn => pn.checked)
                    ? `${plan.name} - ${plan.programNames.filter(pn => pn.checked && pn.name && pn.name.length > 0).map(pn => pn.name)
                        .join(', ')}`
                    : plan.name}</span>
        </span>
    );
}


SelectedPlan.propTypes = {
    plan: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default SelectedPlan;