import { createSelector } from 'reselect';
import * as types from './partnerPaymentsSectionTypes';
import { GET_CLOSED_USAGE_PERIODS, GET_PERIODS } from '../../Shared/Periods/periodsTypes';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import paymentTypesEnum from '../../../enums/paymentTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === types.GET_PAYMENTS || types.DOWNLOAD_PAYMENTS || i === types.GET_PARTNERS || i === GET_CLOSED_USAGE_PERIODS || i === GET_PERIODS
    ).size;

    return size > 0;
};
const getPaymentsFilter = state => state.partnerPaymentsSectionReducer.get('paymentsFilter');
const getPayments = state => state.partnerPaymentsSectionReducer.get('payments');
const getProgramPaymentTypeId = createSelector([getPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['programPaymentType', 'id']);
});
const getSelf = state => state;
const getPaymentTypes = state => state.partnerPaymentsSectionReducer.get('paymentTypes');
const getPeriodId = createSelector([getPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['period', 'id']);
});
const getPartnerId = createSelector([getPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['partner', 'id']);
});
const getShowCorrectedOnly = createSelector([getPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.get('showCorrectedOnly');
});
const getProgramTypeId = createSelector([getPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['programType', 'id']);
});
const getPaymentTypeId = createSelector([getPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['paymentType', 'id']);
});
const getPartners = state => state.partnerPaymentsSectionReducer.get('partners');
const getPeriods = createSelector([getProgramPaymentTypeId, getPaymentTypeId, getSelf], (selectedProgramPaymentTypeID, selectedPaymentTypeID, state) => {
    if (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerPaid
        || (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerMemberPaid && selectedPaymentTypeID === paymentTypesEnum.gmPartnerDues)) {
        return state.periodsReducer.get('closedUsagePeriods');
    }
    else if (selectedProgramPaymentTypeID === programPaymentTypesEnum.memberPaid
        || (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerMemberPaid && selectedPaymentTypeID === paymentTypesEnum.gmPartnerFees)) {
        return state.periodsReducer.get('periodsUntilCurrent');
    }
});
const getDefaultPeriod = createSelector([getPeriods], (periods) => {
    return periods.first();
});

export default createSelector([getIsLoading, getPaymentsFilter, getPayments, getPartners, getProgramPaymentTypeId, getPeriodId, getPartnerId, getShowCorrectedOnly, getPeriods, getDefaultPeriod, getProgramTypeId, getPaymentTypes, getPaymentTypeId],
    (isLoading, paymentsFilter, payments, partners, programPaymentTypeId, periodId, partnerId, showCorrectedOnly, periods, defaultPeriod, programTypeId, paymentTypes, paymentTypeId) => ({
        isLoading, paymentsFilter, payments, partners, programPaymentTypeId, periodId, partnerId, showCorrectedOnly, periods, defaultPeriod, programTypeId, paymentTypes, paymentTypeId
    }));