import partnerGroupMemberhipsPaymentsResources from './partnerGroupMemberhipsPaymentsResources';

export default (values, props) => {
    const errors = {};
    if (values.getIn(['periodFrom', 'id']) > values.getIn(['periodTo', 'id'])) {
        errors.periodFrom = partnerGroupMemberhipsPaymentsResources.errorMessagePeriodRange;
        errors.periodTo = partnerGroupMemberhipsPaymentsResources.errorMessagePeriodRange;
        errors._error = partnerGroupMemberhipsPaymentsResources.errorMessagePeriodRange;
    }
    return errors;
};