import React from 'react';
import NextArrow from '../Slider/CustomArrow/NextArrow';
import PrevArrow from '../Slider/CustomArrow/PrevArrow';
import CustomPaging from '../Slider/CustomPaging';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_testimonial-section.scss';
import '../Slider/_slider.scss';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

function TestimonialSection({ header, children }) {
    return (
        <div className="testimonial-section">
            <div className="testimonial-section__header">{header}</div>
            <div className="testimonial-section__group">
                <hr className="testimonial-section__divider" />
                <Slider
                    nextArrow={<NextArrow/>}
                    prevArrow={<PrevArrow/>}
                    dots
                    speed={400}
                    dotsClass={'custom-slick-dots testimonial-section__custom-slick-dots'}
                    customPaging={CustomPaging}
                >
                    {children}
                </Slider>
            </div>
        </div>

    );
}

TestimonialSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.arrayOf(PropTypes.node)
};

export default TestimonialSection;