import { createSelector } from 'reselect';
import * as types from './partnerGroupMemberhipsPaymentsTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === types.GET_PAYMENTS || i === types.GET_USAGE_PERIODS || types.DOWNLOAD_PAYMENTS
            || i === types.GET_PROGRAM_PAYMENT_TYPE_ID
    ).size;

    return size > 0;
};
const getPaymentsFilter = state => state.partnerGroupMemberhipsPaymentsReducer.get('paymentsFilter');
const getPayments = state => state.partnerGroupMemberhipsPaymentsReducer.get('payments');
const getUsagePeriods = state => state.partnerGroupMemberhipsPaymentsReducer.get('usagePeriods');
const getProgramPaymentTypeID = state => state.partnerGroupMemberhipsPaymentsReducer.get('programPaymentTypeID');

export default createSelector([getIsLoading, getPaymentsFilter, getPayments, getUsagePeriods, getProgramPaymentTypeID],
    (isLoading, paymentsFilter, payments, usagePeriods, programPaymentTypeID) => ({
        isLoading, paymentsFilter, payments, usagePeriods, programPaymentTypeID
    }));