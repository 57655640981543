const guestAreaSharedResources = {
    contactUsDescriptionText: 'Everyone’s wellness journey is different, and our Healthy Contributions Customer Support is with you 24/7. Our goal is to ensure no wellness goal is left unattained or questions left unanswered. If at any time you have questions, need platform help or additional information, contact our Healthy Contributions Customer Support team at any time.',
    contactUsButtonText: 'CONTACT US',
    contactUsTopImageAlt: 'Contact Us Top',
    contactUsTitle: 'Your wellness journey with Healthy Contributions is designed to be your way',
    contactUsBottomImageAlt: 'Contact Us Bottom',
    callToActionSubtitle: 'Wellness. Rewarded.',
    signUpButtonText: 'Sign Up',
    signUpTopImageAlt: 'Sign Up Top',
    requestDemoButtonText: 'Request Demo',
    requestDemoDescriptionText: 'See how Healthy Contributions helps employers, health insurers and partners engage and activate members to participate in wellness programs and reward healthy behaviors to change lives for better health.',
    requestDemoTopImageAlt: 'Request Demo Top',
    activateKeyFobButtonText: 'Activate Your AF Group Membership',
};
export default guestAreaSharedResources;