import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../../../routing/routes';
import { HEALTHY_CONTRIBUTIONS_NAME } from '../../../../constants/companyNames';

function PrivacyArchives() {
    useEffect(() => {
        document.title = 'Privacy Policy Archive Index';
    }, []);
    return <div className="info-page">
        <div className="info-page__title">{HEALTHY_CONTRIBUTIONS_NAME} Privacy Policy Archives</div>
        <div className="info-page__content">
            <div className="info-page__primary-content">
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-paragraph">Below is a list of past Privacy Policies</div>
                </section>
                <section className="info-page__primary-content-section">
                    <div><NavLink className="link link--blue link--overflow-wrap" to={routes.privacypolicy20230110.path}>Privacy Policy January 10, 2023</NavLink></div>
                    <div><NavLink className="link link--blue link--overflow-wrap" to={routes.californiaprivacypolicy20230110.path}>California Privacy Policy January 10, 2023</NavLink></div>
                    <div><NavLink className="link link--blue link--overflow-wrap" to={routes.privacynotice20230824.path}>Privacy Notice August 24, 2023</NavLink></div>
                </section>
            </div>
        </div>
    </div>;
}

export default PrivacyArchives;