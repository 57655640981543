import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// All available icons: https://fonts.google.com/icons?icon.style=Outlined&icon.set=Material+Icons and https://fonts.google.com/icons?icon.style=Filled&icon.set=Material+Icons
function MaterialIcon({ role, icon, className, variant, onClick }) {
    return (
        <span
            role={role}
            onClick={onClick}
            className={classNames([
                { [`material-icons-${variant}`]: variant !== 'filled' },
                { 'material-icons': variant === 'filled' },
                'material-icon', className])}
        >
            {icon}
        </span>
    );
}

MaterialIcon.defaultProps = {
    variant: 'outlined'
};

MaterialIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    role: PropTypes.string
};

export default MaterialIcon;
