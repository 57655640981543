import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../../../routing/routes';
import { HEALTHY_CONTRIBUTIONS_NAME, HEALTHY_CONTRIBUTIONS_LEGAL_NAME, HEALTHY_CONTRIBUTIONS_LEGAL_NAME_WITH_COPYRIGHT } from '../../../../constants/companyNames';
import { PRIVACY_NOTICE } from '../../../../constants/legal';
import { HEALTHY_CONTRIBUTIONS_PRIVACY } from '../../../../constants/emails';
import { HC_PHONE_NUMBER } from '../../../../constants/phones';
import '../../../../styles/_info-page.scss';
import '../../../../styles/_utils.scss';
import { downloadAuthorizedAgentDesignationForm } from './authorizedAgentDesignationFormActions';
import privacyPolicyEffectiveDateFormatter from '../../../../utilities/formatters/privacyPolicyEffectiveDateFormatter';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';

function PrivacyNotice() {
    const effectiveDate = new Date(2024, 2, 14);
    const formattedEffectiveDate = privacyPolicyEffectiveDateFormatter.formatPrivacyPolicyEffectiveDate(effectiveDate);
    const handleDownloadAuthorizedAgentForm = (e) => {
        e.preventDefault();
        downloadAuthorizedAgentDesignationForm();
    };
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle(PRIVACY_NOTICE);
    }, []);
    return <div className="info-page">
        <div className="info-page__title">{`${HEALTHY_CONTRIBUTIONS_NAME} ${PRIVACY_NOTICE}`}</div>
        <div className="info-page__content">
            <div className="info-page__primary-content">
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-paragraph">
                        To review our previous Privacy Policies, please refer to the <NavLink className="link link--blue link--overflow-wrap" to={routes.privacyarchives.path}>previous Privacy Policies</NavLink>.
                    </div>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-paragraph">
                        This {PRIVACY_NOTICE} describes how {HEALTHY_CONTRIBUTIONS_LEGAL_NAME_WITH_COPYRIGHT}, including its officers, directors, employees, affiliates, volunteers, agents and authorized representatives including independent contractors (collectively, “Healthy Contributions,” “we,” “us,” or “our”) collects, uses, shares, and protects personal information. This {PRIVACY_NOTICE} also tells you about your rights and choices with respect to your personal information, and how you can reach us to get answers to your questions.
                    </div>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-title">You can jump to particular topics by going to the headings below:</div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#information-we-collect">Information We Collect</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#how-we-use-information">How We Use Information</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#how-we-share-information">Sharing Of Information</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#your-choices">Your Choices</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#how-we-protect-and-retain-information">How We Protect And Retain Information</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#transmission-of-information-to-other-countries">Transmission Of Information To Other Countries</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#third-party-applications-websites">Third-Party Applications/Websites</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#changes-to-this-privacy-notice">Changes To This {PRIVACY_NOTICE}</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#children">Children</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#contact-information">Contact Information</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#additional-information-for-california-residents">Additional Information For California Residents</a></div>
                    <div className="info-page__primary-content-section-paragraph"><a className="link link--blue" href="#additional-information-for-washington-residents">Additional Information For Washington Residents</a></div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="information-we-collect">
                    <div className="info-page__primary-content-section-title">Information We Collect</div>
                    <div className="info-page__primary-content-section-paragraph">
                        We collect information about you in a variety of ways depending on how you interact with us and our websites, mobile applications (where applicable), products, and services, including:
                    </div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">
                            Directly from you when you provide it to us, such as when you register for an account, sign up to receive communications from us, make a purchase, or contact us by phone, email, or otherwise.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            Automatically through the use of cookies, web beacons, server logs, and other similar technologies when you interact with our websites, mobile applications, advertisements, and emails.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            From other sources, including, for example, our affiliates, business partners, service providers, online social media networks, and other third parties, or from publicly available sources. For example, if you submit a job application or an application for a franchise, we may conduct a background check.
                        </li>
                    </ul>
                    <div className="info-page__primary-content-section-paragraph">
                        The following provides examples of the type of information that we collect in a variety of contexts and how we use that information.
                    </div>
                    <table className="info-page__primary-content-section-table">
                        <thead>
                            <tr className="info-page__primary-content-section-table-header-row">
                                <th className="info-page__primary-content-section-table-header-row-cell">Context</th>
                                <th className="info-page__primary-content-section-table-header-row-cell info-page__primary-content-section-table-header-row-cell--break-all">Types of Information</th>
                                <th className="info-page__primary-content-section-table-header-row-cell info-page__primary-content-section-table-header-row-cell--break-all">Primary Purpose for Collection and Use of Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Account Registration</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">When you create an account or register for our services, we collect your name, contact information (such as your email address, and phone number), profile username and password. We also collect information relating to the actions that you perform while logged into your account.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We have a legitimate interest in providing account related functionalities to our users.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Cookies and First-Party Tracking</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content" dangerouslySetInnerHTML={{ __html: 'We use cookies and similar tracking technologies, such as web beacons (also known as clear GIFs) to collect certain information when you visit our websites or interact with our emails. “Cookies” are small pieces of information that a website sends to a computer&#39;s hard drive while a website is viewed. See our <a class="link link--blue" href="#" alt="Cookie Settings" onclick="window.OneTrust.ToggleInfoDisplay()">Cookie Settings</a> for more information.' }} />
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We have a legitimate interest in making our website operate efficiently.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Cookies and Third-Party Tracking</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content" dangerouslySetInnerHTML={{ __html: 'We may place tracking technology on our website that collects analytics, records how you interact with our website, or allows us to participate in behavior-based advertising. This means that a third party uses technology (e.g., a cookie) to collect information about your use of our website so that they can report analytics to us or provide advertising about products and services tailored to your interests. That third party might also collect information over time and across different websites in order to serve advertisements on our website or other websites. See our <a class="link link--blue" href="#" alt="Cookie Settings" onclick="window.OneTrust.ToggleInfoDisplay()">Cookie Settings</a> for more information.' }} />
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">Where required by law, we base the use of third-party cookies upon consent.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Demographic Information</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We collect personal information, such as your age or location.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We have a legitimate interest in understanding our users and providing tailored services.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Inquiries, Comments, and Feedback</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">If you contact us, we will collect your name and contact information (such as email address), as well as any other content that you send to us, in order to reply.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We have a legitimate interest in receiving, and acting upon, your feedback or issues.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Mailing List</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">When you sign up for one of our mailing lists, we collect your email address or postal address (as applicable).</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We share information about our products and services with individuals that consent to receive such information, where required by law. We also have a legitimate interest in sharing information about our products or services.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Mobile Application Integrations</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We collect information through integrations with HealthKit™ and/or Google Fit™, to allow us to personalize your app experience, to allow our coaches to measure your progress and adjust programming, and for other related uses.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We base mobile application integrations upon consent.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Payments</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We collect your name, mailing address, billing address, email address, phone number, and credit card number when you make a purchase, using a third-party payment processing vendor.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We use your information to perform our contract to provide you with products or services.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Partner Promotion</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We collect information that you provide as part of a co-branded promotion with another company.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We have a legitimate interest in fulfilling our promotions.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Prospective Employees</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">If you are a prospective employee or employee, we may collect detailed information such as your first name, last name, email address, phone number, address, and related information to evaluate your qualifications for employment. This may include, among other things, your Social Security Number. Providing this information is required to be considered for employment.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">In some contexts, we are required by law to collect information about potential employees or applicants. We also have a legitimate interest in using your information to have efficient staffing and work force operations.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Public Health and Safety</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">In certain situations where a serious public health threat has been identified (e.g., Covid-19), we may collect information from employees, guests, and other individuals accessing our facilities. This may include medical and health information, such as body temperature, symptoms, and underlying health conditions.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content"> We have a legitimate interest in protecting the health and safety of our employees and guests. In some jurisdictions we may be required by law, regulation, or governmental order to collect and retain information related to issues of public health and safety. We have a legitimate interest in complying with the laws in the jurisdictions in which we operate.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Surveys</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">When you participate in a survey, we collect information that you provide through the survey. If the survey is provided by a third party service provider, the third party's Privacy Notice applies to the collection, use, and disclosure of your information.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content"> We have a legitimate interest in understanding your opinions, and collecting information relevant to our organization.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Sweepstakes or Contests</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">When you participate in a sweepstakes, contest, or other promotional activity, we collect information about you which includes contact information to notify you if you are selected.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We have a legitimate interest in operating our sweepstakes, contests, and other promotional activities. In some contexts, we are also required by law to collect information about those that enter into our sweepstakes, and we have a legitimate interest in complying with those laws.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Website Interactions</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content"> We use technology to monitor how you interact with our website. This may include which links you click on, or information that you type into our online forms. This may also include information about your device or browser.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content"> We have a legitimate interest in understanding how you interact with our website to better improve it, and to understand your preferences and interests in order to select offerings that you might find most useful. We also have a legitimate interest in detecting and preventing fraud.</span>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Web Logs</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">When you visit our website, we automatically collect certain information, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to a computer when the internet is used), domain name, click-activity, referring website, and/or a date/time stamp for visitors.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We have a legitimate interest in monitoring our networks and the visitors to our websites. Among other things, it helps us understand which of our services is the most popular.</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="info-page__primary-content-section-paragraph">
                        Please note, some of the information described above is mandatory for the performance of certain services, and if not provided, you may not be able to utilize or participate in some of our online services or features.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="how-we-use-information">
                    <div className="info-page__primary-content-section-title">How We Use Information</div>
                    <div className="info-page__primary-content-section-paragraph">
                        In addition to the purposes and uses described above, we use information in the following ways:
                    </div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">
                            To identify you when you visit our websites or any of our locations.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To provide products and services or to process returns or refunds.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To process payments (using a third-party payment processing vendor).
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To create and manage your account and/or membership.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To improve our websites, mobile applications, services, and product offerings.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To conduct analytics and research.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To communicate with you, such as to respond to and/or follow-up on your requests, inquiries, issues, or feedback.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To send you electronic communications you have requested, such as text messages, push notifications, notifications through our mobile application or through your mobile device.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To send marketing and promotional materials including information relating to our products, services, sales, or promotions, or those of our business partners.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To develop and display content and advertising tailored to your interests and preferences.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To detect and protect against malicious, deceptive, fraudulent, or illegal activity, including violation of our policies and terms and conditions, security incidents, and harm to the rights, property, or safety of our company and our users, employees, or others.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To debug, identify and repair errors that impair existing intended functionality of our website and services.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To comply with our legal or regulatory obligations, to establish or exercise our rights, and to defend against a legal claim.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            For internal administrative purposes, as well as to manage our relationships.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            For such other purposes as you may consent (from time to time).
                        </li>
                    </ul>
                    <div className="info-page__primary-content-section-paragraph">
                        Although the sections above describe our primary purpose in collecting your information, in many situations we have more than one purpose. For example, if you complete an online purchase, we may collect your information to perform our contract with you, but we also collect your information as we have a legitimate interest in maintaining your information after your transaction is complete so that we can quickly and easily respond to any questions about your order. As a result, our collection and processing of your information is based in different contexts upon your consent, our need to perform a contract, our obligations under law, and/or our legitimate interest in conducting our business.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        To the extent we maintain and use personal information in a deidentified form, we will not attempt to reidentify the information, except for the purpose of determining whether our deidentification processes satisfy our legal obligations.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="how-we-share-information">
                    <div className="info-page__primary-content-section-title">How We Share Information</div>
                    <div className="info-page__primary-content-section-paragraph">
                        In addition to the specific situations discussed elsewhere in this {PRIVACY_NOTICE}, we may disclose personal information in the following situations:
                    </div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Affiliates and Acquisitions.
                            </span>
                            &nbsp;We may share information with our corporate affiliates (e.g., parent company, sister companies, subsidiaries, joint ventures, or other companies under common control). If another company acquires, or plans to acquire, our company, business, or our assets, we will also share information with that company, including at the negotiation stage.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Franchisees and Business Partners.
                            </span>
                            &nbsp;We may share information with our franchisees, or other business partners with whom we collaborate or work with to provide specific services to you.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Other Disclosures without Your Consent.
                            </span>
                            &nbsp;We may disclose information in response to subpoenas, warrants, or court orders, or in connection with any legal process, or to comply with relevant laws or regulations. We may also disclose your information in order to establish or exercise our rights, to defend against a legal claim, to investigate, prevent, or take action regarding possible illegal activities, suspected fraud, safety of person or property, or a violation of our policies.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Partner Promotion.
                            </span>
                            &nbsp;We may offer contests, sweepstakes, or other promotions with third-party partners. If you decide to enter a contest, sweepstakes, or promotion that is sponsored by a third-party partner, the information that you provide will be shared with us and with them. Their use of your information is not governed by this {PRIVACY_NOTICE}.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Service Providers.
                            </span>
                            &nbsp;We may share your information with service providers. Among other things service providers may help us to maintain our website and/or mobile applications, conduct analytics, administer surveys, provide technical support, process payments, assist with customer services, and manage direct marketing programs on our behalf.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Other Disclosures with Your Consent.
                            </span>
                            &nbsp;We may disclose your information to other third parties when we have your consent or direction to do so.
                        </li>
                    </ul>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="your-choices">
                    <div className="info-page__primary-content-section-title">Your Choices</div>
                    <div className="info-page__primary-content-section-paragraph">
                        Some jurisdictions give you a right to make the following choices regarding your personal information:
                    </div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Access To Your Personal Information.
                            </span>
                            &nbsp;You may request access to your personal information or confirmation that we have information about you. In certain limited circumstances, you may also request to receive access to your data in a portable, machine-readable format.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Changes To Your Personal Information.
                            </span>
                            &nbsp;You may ask us to correct information that is inaccurate or incomplete. Note that we may keep historical information in our backup files as permitted by law. We rely on you to update and correct your personal information. You may edit or deactivate your account information or account at any time by signing into your account on the website or mobile application and navigating through your settings. If our website or mobile application does not permit you to update or correct certain information, you can contact us at the address described below in order to request that your information by modified.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Deletion Of Your Personal Information.
                            </span>
                            &nbsp;You may request that we delete your personal information. If required by law, we will grant a request to delete information, but you should note that in many situations we must keep your personal information to comply with our legal obligations, resolve disputes, enforce our agreements, or for another business purposes.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Opt-out of Sharing for Targeted Advertising.
                            </span>
                            <span dangerouslySetInnerHTML={{ __html: '&nbsp;You may opt-out of online tracking based targeted advertising (e.g., cookies) by clicking the Cookie Settings link <a class="link link--blue" href="#" alt="Cookie Settings" onclick="window.OneTrust.ToggleInfoDisplay()">here</a>. Please note that if you change browsers or computers, or if you clear your browser&#39;s cache, you may need to click the link again to apply your preference. You may also opt-out of other forms of targeted advertising by submitting a request via the “Do Not Sell or Share My Information” button in our&nbsp;' }} />
                            <NavLink className="link link--blue link--overflow-wrap" to={routes.dataprivacyrequest.path}>
                                online portal
                            </NavLink>.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Objection to or Restriction of Certain Processing.
                            </span>
                            &nbsp;In certain limited circumstances, you may object to or restrict our processing of your personal information.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Revocation Of Consent.
                            </span>
                            &nbsp;Where we process your personal information based upon consent, you may revoke consent. Please note, if you revoke your consent for the processing of personal information then we may no longer be able to provide you services.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Online Tracking.
                            </span>
                            &nbsp;We do not currently recognize the “Do Not Track” signal.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Promotional Emails.
                            </span>
                            &nbsp;You may choose to provide us with your email address for the purpose of allowing us to send free newsletters, surveys, offers, and other promotional materials to you, as well as targeted offers from third parties. You can stop receiving promotional emails by following the unsubscribe instructions in emails that you receive. If you decide not to receive promotional emails, we may still send you service related communications.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Promotional Mailings.
                            </span>
                            &nbsp;If at any time you do not want to receive offers and/or circulars from us you can remove yourself from our mailing lists by emailing us (our contact information is below) with “NO SNAIL MAIL” in the subject line along with your name, address, and zip code. Please note that our mailings are prepared in advance of their being sent. Although we will remove your name from our mailing list after receiving your request, you may still receive mailings from us that had been initiated prior to your name being removed.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Promotional Text Messages.
                            </span>
                            &nbsp;If you receive a text message from us that contains promotional information you can opt-out of receiving future text messages by replying “STOP.”
                        </li>
                    </ul>
                    <div className="info-page__primary-content-section-paragraph">
                        Please note, not all of the rights described above are absolute, and they do not apply in all circumstances. In some cases, we may limit or deny your request because the law permits or requires us to do so, or if we are unable to adequately verify your identity. We will not discriminate against individuals who exercise their privacy rights under applicable law.
                    </div>
                    <div className="info-page__primary-content-section-subtitle info-page__primary-content-section-subtitle--regular info-page__primary-content-section-subtitle--regular--italic">Submitting Requests</div>
                    <div className="info-page__primary-content-section-paragraph">
                        You may exercise the rights described above through our&nbsp;
                        <NavLink
                            className="link link--blue link--overflow-wrap"
                            to={routes.dataprivacyrequest.path}
                        >
                            online portal
                        </NavLink>
                        &nbsp;or by contacting us as indicated in the “Contact Information” section below. If you are a California resident, you may also call our toll-free number at <a className="link link--blue" href={`tel:${HC_PHONE_NUMBER}`}>{HC_PHONE_NUMBER}</a>. If we refuse to honor a request, you may appeal our refusal by contacting us with the subject line “Appeal.”
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        Note that, as required by law, we will require you to prove your identity. We may verify your identity by phone call or email. Depending on your request, we will ask for information such as your name, email address and telephone number. Following a request, we will use reasonable efforts to supply, correct or delete personal information about you in our files.
                    </div>
                    <div className="info-page__primary-content-section-subtitle info-page__primary-content-section-subtitle--regular info-page__primary-content-section-subtitle--regular--italic">Authorized Agents</div>
                    <div className="info-page__primary-content-section-paragraph">
                        In some circumstances, you may designate an authorized agent to submit requests to exercise certain privacy rights on your behalf. If you are an authorized agent submitting a request on behalf of an individual, you must attach a copy of a completed&nbsp;
                        <a
                            className="link link--blue link--overflow-wrap"
                            href={routes.authorizedagentform.path}
                            onClick={handleDownloadAuthorizedAgentForm}
                        >
                            Authorized Agent Designation Form
                        </a>&nbsp;
                        indicating that you have permission to act on another person's behalf.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="how-we-protect-and-retain-information">
                    <div className="info-page__primary-content-section-title">How We Protect and Retain Information</div>
                    <div className="info-page__primary-content-section-paragraph">
                        No method of transmission over the internet, or method of electronic storage, is fully secure. While we use reasonable efforts to protect your personal information from unauthorized access, use, or disclosure, we cannot guarantee the security of your personal information. In the event that we are required by law to inform you of a breach to your personal information we may notify you electronically, in writing, or by telephone, if permitted to do so by law.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        Some of our websites and mobile applications permit you to create an account. When you do you will be prompted to create a password. You are responsible for maintaining the confidentiality of your password, and you are responsible for any access to or use of your account by someone else that has obtained your password, whether or not such access or use has been authorized by you. You should notify us of any unauthorized use of your password or account.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        We retain your personal information for only as long as necessary to fulfill the purposes outlined in this {PRIVACY_NOTICE}, including for the purposes of satisfying any legal, accounting, or reporting requirements, unless a longer retention period is required or permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure of the information, the purposes for which we obtained the information and whether we can achieve those purposes through other means, as well as applicable legal requirements.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="transmission-of-information-to-other-countries">
                    <div className="info-page__primary-content-section-title">Transmission Of Information To Other Countries</div>
                    <div className="info-page__primary-content-section-paragraph">
                        As a multi-national company, we transmit information between and among our affiliates. As a result, your information may be processed in a foreign country where privacy laws may be less stringent than the laws in your country. Nonetheless, where possible we take steps to treat personal information using the same privacy principles that apply pursuant to the law of the country in which we first received your information. By submitting your personal information to us you agree to the transfer, storage, and processing of your information in a country other than your country of residence including, but not necessarily limited to, the United States.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="third-party-applications-websites">
                    <div className="info-page__primary-content-section-title">Third-Party Applications/Websites</div>
                    <div className="info-page__primary-content-section-paragraph">
                        For your convenience, we may provide links to websites and other third-party content or services that we do not own or operate. The websites and third-party content to which we link may have separate privacy notices or policies. Please note, we have no control over the privacy practices websites, or services that we do not own. We encourage you to review the privacy policies of any third-party website or application for details about such third party’s privacy practices.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="changes-to-this-privacy-notice">
                    <div className="info-page__primary-content-section-title">Changes To This {PRIVACY_NOTICE}</div>
                    <div className="info-page__primary-content-section-paragraph">
                        Our {PRIVACY_NOTICE} includes an “effective” and “last updated” date. The effective date refers to the date that the current version took effect. The last updated date refers to the date that the current version was last substantively modified.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="children">
                    <div className="info-page__primary-content-section-title">Children</div>
                    <div className="info-page__primary-content-section-paragraph">
                        Our websites and online services are not intended for children under the age of 13 and we do not knowingly collect personal information from children under age 16, without parental consent. Children under the age of 13 should not provide their personal information to us.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="contact-information">
                    <div className="info-page__primary-content-section-title">Contact Information</div>
                    <div className="info-page__primary-content-section-paragraph">
                        If you have any questions, comments, or complaints concerning our privacy practices, or if you need to access this {PRIVACY_NOTICE} in an alternative format due to having a disability, please contact us at the appropriate address below. We will attempt to respond to your requests and to provide you with additional privacy-related information.
                    </div>
                    <address className="info-page__primary-content-section-address">
                        <a className="link link--blue" href={`mailto:${HEALTHY_CONTRIBUTIONS_PRIVACY}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</a><br /><br />
                        {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}<br />
                        Attention: Privacy Department<br />
                        111 Weir Drive<br />
                        Woodbury, Minnesota 55125<br />
                    </address>
                    <div className="info-page__primary-content-section-paragraph">
                        If you are not satisfied with our response, and are in the European Union or United Kingdom, you may have a right to lodge a complaint with your local supervisory authority.
                    </div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="additional-information-for-california-residents">
                    <div className="info-page__primary-content-section-title">Additional Information For California Residents</div>
                    <div className="info-page__primary-content-section-paragraph">
                        California law requires us to disclose the following additional information related to our privacy practices. If you are a California resident, the following privacy disclosures apply to you in addition to the rest of the {PRIVACY_NOTICE}.
                    </div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                California Shine the Light.
                            </span>
                            &nbsp;If you would like more information concerning the categories of personal information (if any) we share with third parties or affiliates for those parties to use for direct marketing, please submit a written request to us using the information in the <a className="link link--blue" href="#contact-information">Contact Information</a> section above.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                California Notice of Financial Incentive.
                            </span>
                            &nbsp;We offer programs that provides certain perks, such as rewards, discounts, and exclusive offers (the “Loyalty Programs”). When you sign up for a Loyalty Program, we may ask you to provide your name, email address, and in some cases your telephone number. Under California law, our Loyalty Programs might be interpreted as “financial incentive” programs as they involve the collection of personal information. We do not assign a monetary value to the information we collect. Based on our reasonable estimate, the value of your personal information to us is related to the value of the expense related to the offer. This value is based on the expense related to offering those free or discounted products or services. You may withdraw from participating in a Loyalty Program at any time by contacting us at the address described in the Loyalty Program terms and conditions. Visit the applicable loyalty program terms to view full program rules, including how to join.
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                Notice of Collection.
                            </span>
                            <span dangerouslySetInnerHTML={{ __html: `&nbsp;The table below describes the categories of personal information we collect, disclose for a business purpose, and “share” for purposes of cross-context behavioral advertising (as those terms are defined by California law). Please note, in addition to the recipients identified below, we may disclose any of the categories of personal information we collect with government entities, as may be needed to comply with law or prevent illegal activity. We do not “sell” your personal information for money. As discussed elsewhere in the ${PRIVACY_NOTICE}, we use cookies and similar tracking technologies for purposes of targeted advertising. For more information, please refer to the <a class="link link--blue" href="#" alt="Cookie Settings" onclick="window.OneTrust.ToggleInfoDisplay()">Cookie Settings</a>. For details regarding how we use personal information, please see the <a className="link link--blue" href="#infomation-we-collect">Information We Collect</a> section of the ${PRIVACY_NOTICE}. For information regarding how long we retain personal information, please refer to the <a className="link link--blue" href="#how-we-protect-and-retain-information">How We Protect And Retain Information</a> section of the ${PRIVACY_NOTICE}.` }} />
                        </li>
                        <table className="info-page__primary-content-section-table">
                            <thead>
                                <tr className="info-page__primary-content-section-table-header-row">
                                    <th className="info-page__primary-content-section-table-header-row-cell" rowSpan={2}>Category of Personal Information</th>
                                    <th className="info-page__primary-content-section-table-header-row-cell" colSpan={2}>Category of Recipients</th>
                                </tr>
                                <tr className="info-page__primary-content-section-table-header-row">
                                    <th className="info-page__primary-content-section-table-header-row-cell info-page__primary-content-section-table-body-row-cell--break-all">Disclosures for a Business Purpose</th>
                                    <th className="info-page__primary-content-section-table-header-row-cell info-page__primary-content-section-table-body-row-cell--break-all">Sharing for Cross-Context Behavioral Advertising</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">
                                            <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Identifiers</span>&nbsp;
                                            - this may include real name, alias, postal address, unique personal identifier, online identifier, email address, account name, or other similar identifiers.
                                        </span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Affiliates or subsidiaries
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Business partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Data analytics providers
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Internet service providers
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Joint marketing partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Operating systems and platforms
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Other Service Providers
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Payment processors and financial institutions
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Social networks
                                            </li>
                                        </ul>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Advertising networks
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">
                                            <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Government Issued Identification</span>&nbsp;
                                            - this may include social security number, driver's license number, or state issued identification number, passport number from our job applicants.
                                        </span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Affiliates or subsidiaries
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Other Service Providers
                                            </li>
                                        </ul>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not Shared.</span>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">
                                            <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Financial Information</span>&nbsp;
                                            - this may include bank account number, credit card number, debit card number, and other financial information.
                                        </span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Affiliates or subsidiaries
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Payment processors and financial institutions
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Other Service Providers
                                            </li>
                                        </ul>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not Shared.</span>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">
                                            <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Health Related Information</span>&nbsp;
                                            - this may include medical information, mental or physical condition or treatment.
                                        </span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not disclosed.</span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not Shared.</span>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">
                                            <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Commercial information</span>&nbsp;
                                            - this may include information about products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
                                        </span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Affiliates or subsidiaries
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Business partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Data analytics providers
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Joint marketing partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Payment processors and financial institutions
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Other Service Providers
                                            </li>
                                        </ul>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Advertising networks
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">
                                            <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Internet or other electronic network activity information</span>&nbsp;
                                            - this may include browsing history, search history, and information regarding an individual’s interaction with an internet website, application, or advertisement.
                                        </span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Affiliates or subsidiaries
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Business partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Data analytics providers
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Joint marketing partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Operating systems and platforms
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Other Service Providers
                                            </li>
                                        </ul>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Advertising networks
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Geolocation data</span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not disclosed.</span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not Shared.</span>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Audio, electronic, visual, or similar information</span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not disclosed.</span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not Shared.</span>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Inferences drawn from any of the information listed above</span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Affiliates or subsidiaries
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Business partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Data analytics providers
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Joint marketing partners
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Other Service Providers
                                            </li>
                                        </ul>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Advertising networks
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr className="info-page__primary-content-section-table-body-row">
                                    <td className="info-page__primary-content-section-table-body-row-cell">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">
                                            <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Additional categories of personal information described in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</span>&nbsp;
                                            - this may include signature and physical characteristics or description.
                                        </span>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <ul className="info-page__primary-content-section-list">
                                            <li className="info-page__primary-content-section-list-item">
                                                Affiliates or subsidiaries
                                            </li>
                                            <li className="info-page__primary-content-section-list-item">
                                                Other Service Providers
                                            </li>
                                        </ul>
                                    </td>
                                    <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                        <span className="info-page__primary-content-section-table-body-row-cell-content">Not Shared.</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <li className="info-page__primary-content-section-list-item">
                            <span className="info-page__primary-content-section-list-item-title">
                                California Sensitive Information Disclosure.
                            </span>
                            &nbsp;We collect the following categories of sensitive personal information (as defined under California law): Social security, driver's license, state identification card, or passport number; precise geolocation; and personal information concerning a consumer’s health. This information is collected in order to locate the nearest business, provide tailored services, evaluate job applicants or franchise applicants, manage our business, or otherwise provide you with services. Note that we do not use such information for any purposes that are not identified within the California Privacy Rights Act Section 1798.121. We do not “sell” or “share” sensitive personal information for purposes of cross-context behavioral advertising.
                        </li>
                    </ul>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="additional-information-for-washington-residents">
                    <div className="info-page__primary-content-section-title">Additional Information For Washington Residents</div>
                    <div className="info-page__primary-content-section-subtitle">Consumer Health Data Privacy Notice</div>
                    <div className="info-page__primary-content-section-paragraph">
                        Because we offer services to consumers in Washington, we are required to disclose the following additional information related to our data handling practices. If you are a Washington resident, the following privacy disclosures apply to you in addition to the rest of the Privacy Notice.  For purposes of this notice, ‘consumer health data’ includes personal information that is linked or reasonably linkable to you and that identifies your past, present, or future physical or mental health status. This also includes any information that we process to associate or identify you with  consumer health data.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        The following chart describes (a) the categories of consumer health data collected; (b) the categories of recipients of such data, (c) the purpose for which the data is collected, including how the data will be used, and (d) the categories of sources from which the data is collected:
                    </div>
                    <table className="info-page__primary-content-section-table">
                        <thead>
                            <tr className="info-page__primary-content-section-table-header-row">
                                <th className="info-page__primary-content-section-table-header-row-cell">Categories of Consumer Health Data</th>
                                <th className="info-page__primary-content-section-table-header-row-cell info-page__primary-content-section-table-header-row-cell--break-all">List of Categories of Recipients</th>
                                <th className="info-page__primary-content-section-table-header-row-cell info-page__primary-content-section-table-header-row-cell--break-all">Purpose/s for which the Data is Collected & How the Data will be Used</th>
                                <th className="info-page__primary-content-section-table-header-row-cell info-page__primary-content-section-table-header-row-cell--break-all">Categories of Sources from Which the Data is Collected</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Personal identifiers</span>
                                    , which may include name, postal address, unique personal identifier, online identifier, email address, account name, or other similar identifiers.
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            Internet service providers
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Operating systems and platforms
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Approved processors
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Joint marketing partners, with consent
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Payment processors and financial institutions
                                        </li>
                                    </ul>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">When you create an account or register for our services, we collect certain personal identifiers.  We use this information to manage your studio membership, account, and related services.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            From the consumer
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Financial Information</span>
                                    , which may include bank account number, credit card number, debit card number, and other financial information.
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            Payment processors and financial institutions
                                        </li>
                                    </ul>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">When you purchase a studio membership or register for our services, our third-party payment processors and financial institutions collect financial information.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            From consumer to our payment processors and/or financial institutions
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Wellness  and exercise information</span>
                                    , which may include exercise goals, progress, sessions, and related information depending on how you interact with us and use our websites, mobile applications, products, and services.
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            Approved processors
                                        </li>
                                    </ul>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">To help you meet your wellness goals and track progress</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            From the consumer
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Indirectly via certain third-party applications, outlined directly below
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Health  related information</span>
                                    , which may include medical information, mental or physical treatment
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">Not disclosed</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">If you are a member, where you choose to share this information with the studio.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            From the consumer
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Commercial information</span>
                                    , which may include information about products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            Internet service providers
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Joint marketing partners with consent
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Operating systems and platforms
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            Approved processors
                                        </li>
                                    </ul>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We use this information to better understand consumer preferences so we can continue to improve our products and services.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            Approved processors
                                        </li>
                                        <li className="info-page__primary-content-section-list-item">
                                            From the consumer
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Internet  or other electronic network activity information</span>
                                    , which may include browsing history, search history, and information regarding an individual’s interaction with an internet website, application, or advertisement.
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">Not disclosed</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We use this information to better understand consumer preferences so we can continue to improve our products and services.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            From the consumer
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="info-page__primary-content-section-table-body-row">
                                <td className="info-page__primary-content-section-table-body-row-cell">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content info-page__primary-content-section-table-body-row-cell-content--bold">Audio, electronic, visual, or similar information</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">Not disclosed</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <span className="info-page__primary-content-section-table-body-row-cell-content">We may collect this kind of information, such as during promotional opportunities, and related services.</span>
                                </td>
                                <td className="info-page__primary-content-section-table-body-row-cell info-page__primary-content-section-table-body-row-cell--break-all">
                                    <ul className="info-page__primary-content-section-list">
                                        <li className="info-page__primary-content-section-list-item">
                                            From the consumer
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="info-page__primary-content-section-subtitle">How You May Exercise your Privacy Rights</div>
                    <div className="info-page__primary-content-section-paragraph">
                        Consumers in the state of Washington have the following rights:
                    </div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">
                            To confirm whether an entity is collecting, sharing, or selling consumer health data and to access such data (along with a list of all third parties and affiliates with whom the entity has shared or sold the consumer health data and an active email address or other online mechanism for contacting these third parties)
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To withdraw consent, and
                        </li>
                        <li className="info-page__primary-content-section-list-item">
                            To request deletion of consumer health data, including information shared or processed by affiliates, processors, contractors, or other third parties ,as well as archived or back-up systems.
                        </li>
                    </ul>
                    <div className="info-page__primary-content-section-paragraph">
                        We have 45 days to respond to your request for access or to withdraw consent, and 30 days to respond to an authenticated deletion request.  Note that we will require you to prove your identity. We may verify your identity by phone call or email. Depending on your request, we will ask for information such as your name, email address, telephone number and studio location.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        You may exercise the rights described above through our <NavLink className="link link--blue link--overflow-wrap" to={routes.dataprivacyrequest.path}>
                            online portal
                        </NavLink> or by contacting us as indicated directly below.  If we refuse to honor a request, you may appeal our refusal by contacting us with the subject line “Appeal.”
                    </div>
                    <address className="info-page__primary-content-section-address">
                        <a className="link link--blue" href={`mailto:${HEALTHY_CONTRIBUTIONS_PRIVACY}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</a><br /><br />
                        {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}<br />
                        Attention: Privacy Department<br />
                        111 Weir Drive<br />
                        Woodbury, Minnesota 55125<br />
                    </address>
                </section>
            </div>
        </div>
        <div className="info-page__subtitle">
            <div className="info-page__subtitle-content">Effective Date: {formattedEffectiveDate}</div>
            <div className="info-page__subtitle-content">Last Update: {formattedEffectiveDate}</div>
        </div>
    </div>;
}
export default PrivacyNotice;