const invitationStatuses = Object.freeze({
    waitingToInvite: 1,
    invited: 2,
    enrolledWaitingForPayment: 3,
    activated: 4,
    suspended: 5,
    waitingToDelete: 6,
    onHold: 7
});

export default invitationStatuses;