import React from 'react';
import PropTypes from 'prop-types';
import './_partner-section.scss';

function PartnersSection({ header, children }) {
    return <div className="partners-section">
        <div className="partners-section__header">{header}</div>
        <div className="partners-section__items">
            {children}
        </div>
    </div>;
}

PartnersSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default PartnersSection;