import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import ErrorSummary from '../../../components/ErrorSummary/ErrorSummary';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Button from '../../../components/controls/Button';
import Table from '../../../components/InfoBox/Table';
import UploadRecipientsForm from '../UploadRecipientsForm/UploadRecipientsForm';
import selectors from './recipientsListSelectors';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import {
    uploadRecipients,
    getVouchers,
    previewVoucherEmail,
    setIsPublishPopupVisible,
    publishVoucherProgram,
    checkVoucherProgramPublished,
    setRecipientsFileContentValidationErrors,
    setIsPreviewPopupVisible
} from './recipientsListActions';
import recipientsListResources from './recipientsListResources';
import Spinner from '../../../components/Spinner/Spinner';
import ConfirmPopup from '../../../components/Popups/ConfirmAlert/ConfirmPopup';
import IconButton from '../../../components/IconButton/IconButton';
import routes from '../../../routing/routes';
import CustomPopup from '../../../components/Popups/CustomPopup/CustomPopup';
import './_recipients.scss';

function RecipientsList({ match, history, isLoading,
    uploadRecipients, recipientsFile, recipientsFileContentValidationErrors,
    vouchers, getVouchers, previewVoucherEmail,
    voucherEmailPeview, isPublishPopupVisible,
    setIsPublishPopupVisible, publishVoucherProgram, checkVoucherProgramPublished,
    isVoucherProgramPublished, setRecipientsFileContentValidationErrors, recipientMetaDataDictionary,
    hasVouchers, isPreviewPopupVisible, setIsPreviewPopupVisible, isEmailPreviewLoading }) {
    useEffect(() => {
        setRecipientsFileContentValidationErrors([]);
    }, []);
    useEffect(() => {
        checkVoucherProgramPublished(match.params.voucherProgramId);
        getVouchers(match.params.voucherProgramId);
        previewVoucherEmail(match.params.voucherProgramId);
    }, [match.params.voucherProgramId]);
    const uploadRecipientsHandler = (formData) => {
        uploadRecipients(match.params.voucherProgramId, formData.toJS().recipientsFile[0])
            .then(() => {
                getVouchers(match.params.voucherProgramId);
                previewVoucherEmail(match.params.voucherProgramId);
            });
    };
    const previewHandler = () => {
        setIsPreviewPopupVisible(true);
    };
    const closePopupHandler = () => {
        setIsPreviewPopupVisible(false);
    };
    const columns = [
        {
            Header: recipientsListResources.columnNameVoucherCode,
            accessor: 'code',
        },
        {
            Header: recipientsListResources.columnNameEmailAddress,
            accessor: 'recipientEmail',
        },
    ];
    for (const key in recipientMetaDataDictionary) {
        columns.push({
            Header: key,
            accessor: key
        });
    }
    columns.push({
        Header: recipientsListResources.columnNameStatus,
        accessor: 'status',
    });
    columns.push({
        Header: recipientsListResources.columnNameFacilityID,
        accessor: 'facilityIDNumber',
    });
    columns.push({
        Header: recipientsListResources.columnNameFacilityName,
        accessor: 'facilityName',
    });
    columns.push({
        Header: recipientsListResources.columnNameFacilityManagerName,
        accessor: 'redeemedBy',
    });
    columns.push({
        Header: recipientsListResources.columnNameRedemptionDate,
        accessor: 'redeemedOn',
    });
    const publishVoucherProgramHandler = () => {
        publishVoucherProgram(match.params.voucherProgramId).then(() => {
            checkVoucherProgramPublished(match.params.voucherProgramId);
        });
    };
    const showPublishPopup = () => {
        setIsPublishPopupVisible(true);
    };
    const hidePublishPopup = () => {
        setIsPublishPopupVisible(false);
    };
    return <>
        {isLoading ?
            (
                <Spinner />
            )
            : (<div className="recipients col-xs-12">
                <h2 className="layout__title">{recipientsListResources.headerRecipients}</h2>
                <InfoBox bordered>
                    <IconButton clickHandler={() => { history.push(generatePath(routes.voucherProgramsEdit.path, match.params)); }} text={recipientsListResources.labelBack}
                        icon={'arrow_back'}
                    />
                    {!isVoucherProgramPublished && <div className="m-y-20">
                        <UploadRecipientsForm onSubmit={uploadRecipientsHandler} match={match}
                            history={history}
                            initialValues={
                                {
                                    recipientsFile
                                }
                            }
                        />
                        <ErrorSummary errors={recipientsFileContentValidationErrors} />
                    </div>}
                    <Table columns={columns} data={vouchers.toJS()} />
                    <CustomPopup showPopup={isPreviewPopupVisible} body={<ReactSrcDocIframe srcDoc={voucherEmailPeview} />}
                        title={recipientsListResources.labelPreview}
                        onCancel={closePopupHandler}
                        isLoading={isEmailPreviewLoading}
                        closeOnClickOutside
                    />
                    <ConfirmPopup showPopup={isPublishPopupVisible}
                        body={<div className="message">{recipientsListResources.textPublishConfirmation}</div>}
                        onConfirm={publishVoucherProgramHandler}
                        onCancel={hidePublishPopup}
                    />
                    <div className="grid__row recipients__button-container">
                        <div className="col-xs-12 col-md-3 col-lg-2">
                            <Button
                                size="medium"
                                type="button"
                                disabled={!hasVouchers}
                                onClick={previewHandler}
                            >
                                {recipientsListResources.labelPreview}
                            </Button>
                        </div>
                        {!isVoucherProgramPublished && <div className="col-xs-12 col-md-3 col-lg-2">
                            <Button
                                size="medium"
                                type="button"
                                onClick={showPublishPopup}
                                disabled={!hasVouchers}
                            >
                                {recipientsListResources.labelPublish}
                            </Button>
                        </div>}
                    </div>
                </InfoBox>
            </div>
            )}</>;
}

RecipientsList.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    uploadRecipients: PropTypes.func.isRequired,
    getVouchers: PropTypes.func.isRequired,
    previewVoucherEmail: PropTypes.func.isRequired,
    vouchers: PropTypes.object.isRequired,
    recipientsFileContentValidationErrors: PropTypes.object.isRequired,
    voucherEmailPeview: PropTypes.string,
    isPublishPopupVisible: PropTypes.bool.isRequired,
    setIsPublishPopupVisible: PropTypes.func.isRequired,
    publishVoucherProgram: PropTypes.func.isRequired,
    isVoucherProgramPublished: PropTypes.bool.isRequired,
    setRecipientsFileContentValidationErrors: PropTypes.func.isRequired,
    recipientMetaDataDictionary: PropTypes.object.isRequired,
    hasVouchers: PropTypes.bool.isRequired,
    isPreviewPopupVisible: PropTypes.bool.isRequired,
    setIsPreviewPopupVisible: PropTypes.func.isRequired,
    isEmailPreviewLoading: PropTypes.bool.isRequired,
};

const actions = {
    uploadRecipients,
    getVouchers,
    previewVoucherEmail,
    setIsPublishPopupVisible,
    publishVoucherProgram,
    checkVoucherProgramPublished,
    setRecipientsFileContentValidationErrors,
    setIsPreviewPopupVisible
};

export default connect(selectors, actions)(RecipientsList);
