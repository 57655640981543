import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../utilities/form';
import InputText from '../../../components/controls/InputText';
import Button from '../../../components/controls/Button';
import FormGroup from '../../../components/controls/FormGroup';
import changePasswordResources from './changePasswordResources';
import validate, {
    oldPasswordRequired,
    newPasswordRequired,
    confirmPasswordRequired,
    passwordRegex,
    oldPasswordMaxLength30,
    newPasswordMaxLength30,
    confirmPasswordMaxLength30
} from './changePasswordValidate';

function ChangePasswordForm({ handleSubmit, submitting }) {
    return (
        <FormGroup onSubmit={handleSubmit}>
            <Field
                id="old_pass"
                name="oldPassword"
                label={changePasswordResources.labelOldPassword}
                secureTextEntry
                component={InputText}
                validate={[
                    oldPasswordRequired,
                    oldPasswordMaxLength30,
                    passwordRegex
                ]}
            />
            <Field
                id="new_pass"
                name="newPassword"
                label={changePasswordResources.labelNewPassword}
                secureTextEntry
                component={InputText}
                validate={[
                    newPasswordRequired,
                    newPasswordMaxLength30,
                    passwordRegex
                ]}
            />
            <Field
                id="confirm_pass"
                name="confirmPassword"
                label={changePasswordResources.labelConfirmPassword}
                secureTextEntry
                component={InputText}
                validate={[
                    confirmPasswordRequired,
                    confirmPasswordMaxLength30,
                    passwordRegex
                ]}
            />
            <Button size="medium" disabled={submitting}>
                {changePasswordResources.buttonSave}
            </Button>
        </FormGroup>
    );
}

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'changePassword',
    validate,
    ...defaultFormProperties
})(ChangePasswordForm);
