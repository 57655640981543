import React from 'react';
import TextArea from './TextArea';

const ReduxTextArea = ({ input, meta, ...custom }) => {
    return <TextArea {...meta} {...input}
        {...custom}
           />;
};

export default ReduxTextArea;
