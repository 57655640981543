import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import solutionsSectionResources from './solutionsSectionResources';
import CustomLink from '../controls/CustomLink';

function SolutionsSectionItem({ materialIconName, header, description, linkPath, isExternalLink }) {
    return <div className="solutions-section__item">
        <MaterialIcon className="solutions-section__item-icon" icon={materialIconName} />
        <div className={'solutions-section__item-header'}>{header}</div>
        <div className="solutions-section__item-description">{description}</div>
        {linkPath
            ?
                <div className="solutions-section__link-container">
                    <div className="solutions-section__link-spacer" />
                    <CustomLink className="solutions-section__link" linkPath={linkPath}
                        isExternalLink={isExternalLink}
                    >
                        {solutionsSectionResources.linkLearnMore}
                        <MaterialIcon className={'solutions-section__link-icon'} icon="arrow_forward" />
                    </CustomLink>
                </div>
            :
                <></>
        }
    </div>;
}

SolutionsSectionItem.propTypes = {
    materialIconName: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    description: PropTypes.string,
    linkPath: PropTypes.string,
    isExternalLink: PropTypes.bool
};

export default SolutionsSectionItem;